import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/PKM.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="ПК | Калашников100"
        description="Несмотря на то, что Калашников поздно вступил в конкурсную гонку, ему удалось доказать превосходство своей конструкции. Пулемет Калашникова долгие годы был основным образцом пулемета под винтовочный патрон"
      />
      <Content back="/cabinet/">
        <h1>ПК</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="PKM" style={{ maxWidth: 656 }} />
          <p>
            Создание автоматического оружия под отечественный трехлинейный
            винтовочный патрон являлось очень сложной задачей для оружейных
            конструкторов. Особенно это касалось пулеметов. Хотя первые попытки
            создать ручные образцы с&nbsp;ленточным питанием предпринимались еще
            в&nbsp;20-х годах, долгое время они не&nbsp;давали результата,
            полностью устраивающего военных.
          </p>
          <p>
            В&nbsp;1955 году ГАУ объявило новый конкурс
            на&nbsp;&laquo;7,62&nbsp;мм единый ротный и батальонный пулемет под
            винтовочный патрон&raquo;. Первоначально в&nbsp;нем участвовали
            представители&nbsp;КБ из&nbsp;Тулы и&nbsp;Коврова. Наиболее
            перспективным считался пулемет системы Никитина, работавший
            на&nbsp;принципе прямой подачи патрона из&nbsp;ленты специальной
            конструкции. Была даже выпущена серия этих пулеметов для войсковых
            испытаний.
          </p>
          <p>
            Калашников начал работу над своим проектом пулемета только в 1958
            году и первоначально не&nbsp;мог доказать преимущества своей
            конструкции. В&nbsp;его пулемете была использована схема подачи
            в&nbsp;два этапа, когда патрон сначала извлекается из&nbsp;ленты,
            а&nbsp;затем подается в&nbsp;патронник. Хотя конструктивно этот
            вариант сложнее, при правильном подходе он обеспечивает большую
            надежность работы.
          </p>
          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8etbjc348514kmmmephiippu"
            margin
          />

          <p>
            Несмотря на&nbsp;то, что в&nbsp;ходе конкурса образцы Калашникова
            были сочтены малоперспективными для доработки, серия проведенных
            в&nbsp;1960 году испытаний продемонстрировала, что пулемет системы
            Калашникова более надежно работает в&nbsp;затрудненных условиях,
            а&nbsp;также превосходит пулемет Никитина по&nbsp;кучности при
            стрельбе со&nbsp;станка.{" "}
          </p>

          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q61k4n0a300947zjfpwiw4"
            margin
          />

          <p>
            В&nbsp;итоге именно образец Калашникова был принят
            на&nbsp;вооружение советской армии, на&nbsp;долгие годы став
            основным образцом пулемета под винтовочный патрон. В&nbsp;1969 году
            он был модернизирован, получив новый ствол и&nbsp;став легче
            на&nbsp;1,5&nbsp;кг. Надо отметить, что по&nbsp;сравнению
            с&nbsp;основным западным единым пулеметом FN&nbsp;MAG
            модернизированный пулемет Калашникова значительно легче, в&nbsp;том
            числе и в&nbsp;станковом варианте.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q2el0mi0js0a47n69s1edl"
            title="Образец из коллекции Военно-исторического музея артиллерии, инженерных войск и войск связи"
            margin
          />

          <p>
            В&nbsp;настоящее время ПКМ и&nbsp;его более поздние модификации (ПКП
            &laquo;Печенег&raquo;, &laquo;Печенег-СП&raquo;) продолжают
            оставаться основными едиными пулеметами на вооружении Российской
            армии.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
