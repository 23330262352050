import React from "react"

import SEO from "../components/layout/seo"
import About from "../components/content/about"
import Background from "../components/background/background"
import Footer from "../components/layout/footer"

import src from "../images/background/about.jpg"
import src2x from "../images/background/about_2x.jpg"

export const aboutImg = [src, src2x]

const AboutPage = () => {
  return (
    <>
      <Background src={aboutImg} disableMove>
        <SEO
          title="O проекте | Калашников100"
          description="Обстановка на столе великого оружейника весьма аскетична: здесь нет ничего лишнего - только самые ценные вещи для Михаила Тимофеевича"
        />
        <About />
        <Footer />
      </Background>
    </>
  )
}

export default AboutPage
