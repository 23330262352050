import React, { useState } from "react"
import Img from "gatsby-image"
import Swiper from "react-id-swiper"
import styles from "./slider.module.css"
import "../../../node_modules/swiper/css/swiper.min.css"
import Arrow from "../../svg/arrow.svg"

const Slider = ({ images }) => {
  const [swiper, updateSwiper] = useState(null)

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }

  return (
    <div className={styles.container}>
      <Swiper getSwiper={updateSwiper}>
        {images.map((image, i) => (
          <Img key={i} fluid={image} />
        ))}
      </Swiper>
      <button className={`js-hover ${styles.prev}`} onClick={goPrev}>
        <Arrow />
      </button>
      <button className={`js-hover ${styles.next}`} onClick={goNext}>
        <Arrow />
      </button>
    </div>
  )
}

export default Slider
