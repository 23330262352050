import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/PPK.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Пистолет-пулемет образца 1942 года | Калашников100 "
        description="Конструкция пистолета-пулемета образца 1942 года была принята на вооружения в условиях войны, положив начало успешной карьеры легендарного оружейника М.Т. Калашникова"
      />
      <Content back="/cabinet/">
        <h1>Пистолет-пулемет образца 1942 года</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="PPK" style={{ maxWidth: 656 }} />

          <p>
            Одной из&nbsp;проблем, ярко проявившихся в&nbsp;начале Великой
            Отечественной войны, стал вопрос вооружения экипажей бронетехники.
            В&nbsp;штатной укладке танков находился пистолет-пулемет Дегтярева
            или Шпагина. Это оружие с высокими боевыми данными было
            не&nbsp;очень удобно в&nbsp;момент экстренного покидания подбитой
            машины. Танкисту Михаилу Калашникову тоже довелось попасть
            в&nbsp;такую ситуацию&nbsp;и, получив отпуск по&nbsp;ранению,
            он&nbsp;занялся созданием компактного пистолета-пулемета для своих
            боевых товарищей.
          </p>

          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8f065f430110kmmmv5erhk0b"
            title="Образец из коллекции Военно-исторического музея артиллерии, инженерных войск и войск связи"
            margin
          />

          <p>
            Первый экземпляр, созданный в&nbsp;депо станции Матай,
            не&nbsp;сохранился, но опыт его создания помог в&nbsp;работе над
            вторым вариантом пистолета-пулемета, построенного
            по&nbsp;оригинальной схеме автоматики с полусвободным затвором
            и&nbsp;тормозящей муфтой. Над ним Калашников работал уже
            в&nbsp;Алма-Ате при содействии специалистов эвакуированного
            Московского авиационного института. Именно этот образец стал первым
            оружием Калашникова, испытанным на&nbsp;стрелковом полигоне ГАУ КА.
            Этот пистолет-пулемет неплохо проявил себя и&nbsp;был отмечен как
            оригинальная конструкция, заслуживающая внимания.
          </p>

          <p>
            Однако разработка Калашникова значительно уступала уже появившемуся
            пистолету-пулемету Судаева по&nbsp;технологичности изготовления:
            детали ППК требовали большого количества фрезерных и&nbsp;слесарных
            работ. В&nbsp;условиях войны это было неприемлемо для массового
            оружия. Хотя ППК так и&nbsp;не&nbsp;был принят на&nbsp;вооружение,
            именно с&nbsp;него началась карьера нового конструктора стрелкового
            оружия&nbsp;&mdash; М.Т. Калашникова.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
