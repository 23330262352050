import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Photo 4.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Религия | Калашников100"
        description="Отношения Михаила Тимофеевича Калашникова с религией нельзя назвать простыми и однозначными. Однако конструктор всегда задавался глубоко духовными вопросами"
      />
      <Content back="/painting/">
        <h1>Религия</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="Религия" style={{ maxWidth: 656 }} />
          <blockquote>
            <p>
              <i>
                &laquo;Говорят, Господь сделал мир так, что все сложное
                в&nbsp;нем&nbsp;&mdash; не нужно, а&nbsp;все нужное&nbsp;&mdash;
                просто. Под этим девизом я&nbsp;всю жизнь работаю
                &mdash;&nbsp;надежно и&nbsp;просто&raquo;.
              </i>
            </p>
            <p>
              <b>
                <i>М. Т. Калашников:</i>
              </b>
            </p>
          </blockquote>
          <br />
          <p>
            История отношений Калашникова с&nbsp;религией долгая
            и&nbsp;непростая. Еще в раннем детстве маленький Миша Калашников
            посещал бывшую церковно-приходскую школу.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rnkhni00fe0a47zr810uwn"
            margin
          />

          <p>
            Годы его обучения в&nbsp;ней пришлись на&nbsp;период активного
            введения антирелигиозного образования: школы обретали
            профессиональную направленность, что тесно связывало
            их&nbsp;с&nbsp;заводами и&nbsp;предприятиями.
          </p>

          <p>
            Михаил Тимофеевич всегда считал себя советским человеком, однако в
            последние годы жизни стал посещать ижевский храм, увлеченно читал
            библию и&nbsp;задавал вопросы на&nbsp;встречах со&nbsp;священниками.
            Он&nbsp;также начал исповедоваться и&nbsp;причащаться. Сам
            он&nbsp;так говорил о&nbsp;своем отношении к вере:
            &laquo;Я&nbsp;уважаю православную веру&nbsp;&mdash; это вера моих
            предков. Мои родители Бога почитали и&nbsp;нас, детей, приучали
            к&nbsp;христианским ценностям&raquo;.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2qho0s6003j0a479m5i73xc"
            margin
          />

          <p>
            Калашников не&nbsp;только много сделал, но&nbsp;и&nbsp;сам принял
            активное участие в&nbsp;воссоздании Михайловского собора
            в&nbsp;Ижевске.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2qhl36w002p0a473ewhl26l"
            margin
          />

          <p>
            Именно в&nbsp;этом соборе произошло прощание с&nbsp;Михаилом
            Тимофеевичем после его смерти.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2qhiqou0a930947p9ph1hnv"
            margin
          />

          <p>
            К&nbsp;100-летию Михаила Тимофеевича Калашникова на&nbsp;его
            родине&nbsp;&mdash; в&nbsp;селе Курья Алтайского края&nbsp;&mdash;
            была отреставрирована церковь Знамения, в которой был крещен
            конструктор.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2qdol3c05q00947zenxxe7t"
            margin
          />
        </div>
      </Content>
    </>
  )
}

export default MapPage
