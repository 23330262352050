import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styles from "./content.module.css"
import utils from "../utils.module.css"

// import src from "../../images/pattern.png"
import src from "../../images/pattern.png"
import Close from "../../svg/close.svg"

const Content = ({ children, back }) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `url(${src})`,
      }}
    >
      <div className={styles.content}>
        <>
          <div className={`${styles.contentWidth} ${styles.closeWrap}`}>
            <AniLink
              paintDrip
              hex="#191919"
              duration={0.45}
              className={`${styles.close} js-hover`}
              to={back}
            >
              <Close />
            </AniLink>
          </div>
          {children}
          <div className={styles.back}>
            <AniLink
              paintDrip
              hex="#191919"
              duration={0.45}
              className={`${utils.button} ${utils.buttonBlack} ${utils.buttonCenter} js-hover`}
              to={back}
            >
              ← Назад
            </AniLink>
          </div>
        </>
      </div>
    </div>
  )
}

export default Content
