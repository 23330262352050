import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
    placeholderImage2: {
      childImageSharp: { fluid: kalash2 },
    },
    placeholderImage3: {
      childImageSharp: { fluid: kalash3 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Folder 1.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "content/Folder 2.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage3: file(relativePath: { eq: "content/MapWar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Война | Калашников100"
        description="События войны, в которых конструктор принимал участие в должности командира танка, повлияли на всю дальнейшую деятельность М.Т. Калашникова, определив цель всех его дальнейших рахработок"
      />
      <Content back="/table/">
        <h1>Война</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <p>
            Калашников был призван в&nbsp;Красную армию осенью 1938&nbsp;года.
            На&nbsp;службе он&nbsp;получил специальность механика-водителя танка
            и&nbsp;числился в&nbsp;12-й танковой дивизии 8-го механизированного
            корпуса. Хотя 8-й мехкорпус Рябышева был одним из&nbsp;самых мощных
            и&nbsp;укомплектованных в&nbsp;западных округах, танковая битва под
            Дубно (Июнь 1941&nbsp;г.) стала для него роковой&nbsp;&mdash; корпус
            утратил почти всю матчасть и&nbsp;понес большие потери в личном
            составе.
          </p>
          <br />
          <Img fluid={kalash3} alt="Схема боев" style={{ maxWidth: 656 }} />
          <br />
          <p>
            Направляясь из&nbsp;Ленинграда на&nbsp;фронт, Калашников встретил
            своего бывшего командира танка и&nbsp;в&nbsp;итоге оказался
            в&nbsp;учебном танковом батальоне, где получил назначение
            на&nbsp;должность командира танка Т-34 с&nbsp;присвоением звания
            старшего сержанта. На&nbsp;фронт Михаил Тимофеевич попал
            в&nbsp;составе 216-го танкового полка 108-й танковой дивизии. В
            первый бой полк пошел в&nbsp;конце августа 1941 года в&nbsp;ходе
            контрудара Брянского фронта в&nbsp;составе подвижной группы генерала
            Ермакова. После нескольких дней сражений с&nbsp;тяжелыми потерями
            части дивизии сумели прорваться из&nbsp;окружения. В&nbsp;документах
            отдельно отмечено, что из окружения удалось вывести транспорт
            с&nbsp;ранеными.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rnn0q910h309471tvq7g8e"
            margin
          />
          <p>
            Согласно воспоминаниям Калашникова, он&nbsp;был ранен
            &laquo;в&nbsp;начале октября 1941 года под Брянском&raquo;. 108-я
            дивизия действительно вновь попала в окружение в&nbsp;начале
            октября, однако по&nbsp;имеющейся информации можно сделать вывод,
            что Михаил был ранен еще в&nbsp;первых, сентябрьских боях.
          </p>{" "}
          <br />
          <Img fluid={kalash} alt="Война" style={{ maxWidth: 356 }} /> <br />
          <p>
            В&nbsp;любом случае именно этот опыт боев заставил Михаила
            Калашникова задуматься о&nbsp;необходимости создания компактного
            пистолета-пулемета для вооружения экипажей танков. Именно это оружие
            и&nbsp;стало его первой работой как конструктора-оружейника.
          </p>{" "}
          <br />
          <Img fluid={kalash2} alt="Война" style={{ maxWidth: 356 }} />
        </div>
      </Content>
    </>
  )
}

export default MapPage
