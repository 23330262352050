import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"
import Slider from "../../components/content/slider"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
    itemMuseum1: {
      childImageSharp: { fluid: itemMuseum1 },
    },
    itemMuseum2: {
      childImageSharp: { fluid: itemMuseum2 },
    },
    itemMuseum3: {
      childImageSharp: { fluid: itemMuseum3 },
    },
    itemMuseum4: {
      childImageSharp: { fluid: itemMuseum4 },
    },
    itemMuseum5: {
      childImageSharp: { fluid: itemMuseum5 },
    },
    itemMuseum6: {
      childImageSharp: { fluid: itemMuseum6 },
    },
    itemMuseum7: {
      childImageSharp: { fluid: itemMuseum7 },
    },
    itemMuseum8: {
      childImageSharp: { fluid: itemMuseum8 },
    },
    itemMuseum9: {
      childImageSharp: { fluid: itemMuseum9 },
    },
    itemMuseum10: {
      childImageSharp: { fluid: itemMuseum10 },
    },
    itemMuseum11: {
      childImageSharp: { fluid: itemMuseum11 },
    },
    itemMuseum12: {
      childImageSharp: { fluid: itemMuseum12 },
    },
    itemMuseum13: {
      childImageSharp: { fluid: itemMuseum13 },
    },
    itemMuseum14: {
      childImageSharp: { fluid: itemMuseum14 },
    },
    itemMuseum15: {
      childImageSharp: { fluid: itemMuseum15 },
    },
    itemRoom: {
      childImageSharp: { fluid: itemRoom },
    },
    itemRoom2: {
      childImageSharp: { fluid: itemRoom2 },
    },
    itemRoom3: {
      childImageSharp: { fluid: itemRoom3 },
    },
    itemRoom4: {
      childImageSharp: { fluid: itemRoom4 },
    },
    itemRoom5: {
      childImageSharp: { fluid: itemRoom5 },
    },
    itemRoom6: {
      childImageSharp: { fluid: itemRoom6 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Photo 3.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum1: file(relativePath: { eq: "content/museum/museum_01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum2: file(relativePath: { eq: "content/museum/museum_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum3: file(relativePath: { eq: "content/museum/museum_03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum4: file(relativePath: { eq: "content/museum/museum_04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum5: file(relativePath: { eq: "content/museum/museum_05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum6: file(relativePath: { eq: "content/museum/museum_06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum7: file(relativePath: { eq: "content/museum/museum_07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum8: file(relativePath: { eq: "content/museum/museum_08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum9: file(relativePath: { eq: "content/museum/museum_09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum10: file(relativePath: { eq: "content/museum/museum_10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum11: file(relativePath: { eq: "content/museum/museum_11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum12: file(relativePath: { eq: "content/museum/museum_12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum13: file(relativePath: { eq: "content/museum/museum_13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum14: file(relativePath: { eq: "content/museum/museum_14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemMuseum15: file(relativePath: { eq: "content/museum/museum_15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom: file(relativePath: { eq: "content/museum/museamRoom1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom2: file(relativePath: { eq: "content/museum/museamRoom2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom3: file(relativePath: { eq: "content/museum/museamRoom3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom4: file(relativePath: { eq: "content/museum/museamRoom4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom5: file(relativePath: { eq: "content/museum/museamRoom5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemRoom6: file(relativePath: { eq: "content/museum/museamRoom6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Мемориальный музей М.Т. Калашникова | Калашников100"
        description="В создании Мемориального музея М.Т. Калашникова, посвященного его работам и личности, конструктор лично принимал участие, собирая и передавая экспонаты на малую родину"
      />
      <Content back="/painting/">
        <h1>Мемориальный музей М.Т. Калашникова</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img
            fluid={kalash}
            alt="Мемориальный музей"
            style={{ maxWidth: 656 }}
          />
          <p>
            Музей в&nbsp;Курье&nbsp;&mdash; единственный мемориальный музей
            великого конструктора. Он&nbsp;посвящен не&nbsp;только разработкам
            Калашникова, но&nbsp;и жизни Михаила Тимофеевича. Это филиал
            Алтайского Государственного Краеведческого Музея и&nbsp;был открыт
            15&nbsp;ноября 2013&nbsp;года. Музей расположен
            в&nbsp;здании-памятнике архитектуры деревянного зодчества, которое
            было построено в&nbsp;1905&nbsp;году. Место выбрано неслучайно:
            именно там располагалась сельская школа, в&nbsp;которой в&nbsp;20е
            годы учился будущий конструктор.
          </p>
        </div>
        <Slider
          images={[
            itemMuseum1,
            itemMuseum2,
            itemMuseum3,
            itemMuseum4,
            itemMuseum5,
            itemMuseum6,
            itemMuseum7,
            itemMuseum8,
            itemMuseum9,
            itemMuseum10,
            itemMuseum11,
            itemMuseum12,
            itemMuseum13,
            itemMuseum14,
            itemMuseum15,
          ]}
        />
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <p>
            Мемориальный музей состоит из&nbsp;5&nbsp;залов, каждый
            из&nbsp;которых рассказывает об&nbsp;одной из&nbsp;сторон жизни М.Т.
            Калашникова.
          </p>
          <h1>Зал 1 «Алтайский парень»</h1>
          <p>
            <center>
              Детство Михаила Калашникова, его жизнь на&nbsp;Алтае
            </center>
          </p>
        </div>
        <ImagePoints
          image={itemRoom}
          alt="Детство Михаила Калашникова, его жизнь на Алтае"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2oqao8n08ia0947misacq3w",
              position: [49, 37],
            },
            {
              href: "https://kalashnikov.media/embed/ck2oqezl0gwfd0a479n80unso",
              position: [80, 40],
            },
          ]}
        />
        <h1>Зал 2 «Человек-автомат»</h1>
        <p>
          <center>Конструкторская работа М.Т. Калашникова</center>
        </p>
        <ImagePoints
          image={itemRoom2}
          alt="Конструкторская работа М.Т. Калашникова"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2oqlsvb08jm09479u9diuql",
              position: [28, 67],
            },
          ]}
        />
        <h1>Зал 3 «Семья»</h1>
        <p>
          <center>Этот зал воссоздает кабинет М.Т. Калашникова</center>
        </p>
        <ImagePoints
          image={itemRoom3}
          alt="Этот зал воссоздает кабинет М.Т. Калашникова"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2oqwahw08mi0947ggskq31x",
              position: [49, 60],
            },
          ]}
        />
        <h1>Зал 4 «Арсенал»</h1>
        <p>
          <center>
            Здесь представлено личное оружие М.Т. Калашникова, а также его
            разработки
          </center>
        </p>
        <ImagePoints
          image={itemRoom4}
          alt="Здесь представлено личное оружие М.Т. Калашникова, а также его разработки - Часть 1"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2ovehje08tg0947jkkd8g5r",
              position: [30, 85],
            },
            {
              href: "https://kalashnikov.media/embed/ck2oqyxvy08n20947t7p2zd3g",
              position: [89, 42],
            },
          ]}
        />
        <ImagePoints
          image={itemRoom5}
          alt="Здесь представлено личное оружие М.Т. Калашникова, а также его разработки - Часть 2"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2ov85pa08rr0947hqlf4t06",
              position: [25, 45],
            },
            {
              href: "https://kalashnikov.media/embed/ck2ovaxnqh36k0a471i4jzbvh",
              position: [55, 50],
            },
          ]}
        />
        <h1>Зал 5 «Человек-легенда»</h1>
        <p>
          <center>
            Зал посвящен международному признанию М.Т. Калашникова
          </center>
        </p>
        <ImagePoints
          image={itemRoom6}
          alt="Зал посвящен международному признанию М.Т. Калашникова"
          points={[
            {
              href: "https://kalashnikov.media/embed/ck2p3vxos08x10947ti1bgufy",
              position: [50, 35],
            },
          ]}
        />
      </Content>
    </>
  )
}

export default MapPage
