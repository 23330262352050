import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/TT.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Личный пистолет ТТ | Калашников100"
        description="Изобретательность Калашникова проявлялась не только в конструировании, но и в решении других житейских ситуаций. Одним из ярких примеров находчивости служит история о его личном пистолете ТТ"
      />
      <Content back="/table/">
        <h1>Личный пистолет М.Т. Калашникова</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img
            fluid={kalash}
            alt="Личный пистолет М.Т. Калашникова"
            style={{ maxWidth: 656 }}
          />
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2pt8itj09b80947vpbwi4h5"
            margin
            title="Образец из&nbsp;коллекции Военно-исторического музея артиллерии, инженерных войск и&nbsp;войск связи"
          />

          <p>
            В&nbsp;штабе округа в&nbsp;Ташкенте Михаилу Тимофеевичу выдали
            пистолет ТТ&nbsp;1941 года производства, который ему было позволено
            носить при себе. Он&nbsp;был ему очень дорог, что подтверждает
            произошедший позже случай в&nbsp;Матае.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rn6gbc0zsd0947tdwl73mq"
            margin
          />
          <p>
            В&nbsp;то&nbsp;время Михаил Тимофеевич работал над
            пистолетом-пулеметом в&nbsp;депо. Вечерами, после рабочего дня,
            на&nbsp;отдых собиралась молодежь, в&nbsp;том числе и&nbsp;друг
            Михаила Калашникова&nbsp;&mdash; Евгений Кравченко. Компания часто
            подшучивала над конструктором, якобы он&nbsp;боится стрелять
            из&nbsp;своего пистолета. Тогда Михаил Тимофеевич, не&nbsp;стерпев
            насмешек, открыл форточку и&nbsp;прямо из&nbsp;нее произвел
            несколько выстрелов в&nbsp;воздух. Конечно, такие проделки
            не&nbsp;проходили незамеченными, и&nbsp;ТТ был изъят.
          </p>
          <p>
            Михаил Тимофеевич вскоре придумал, как вернуть себе
            пистолет&nbsp;&mdash; он направил телеграмму секретарю
            по&nbsp;оборонной промышленности ЦК&nbsp;КП Казахстана К.
            Кайшигулову, в&nbsp;которой описал, как ночью им&nbsp;были проведены
            стрелковые испытания, связанные с&nbsp;разработкой светящихся
            патронов. В&nbsp;своем обращении конструктор просил издать приказ о
            возвращении ему личного оружия. Спустя два-три дня такое указание
            действительно поступило в&nbsp;особый отдел, и&nbsp;ТТ вернулся
            к&nbsp;своему изобретательному владельцу.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
