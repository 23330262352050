import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Carbine.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Карабин Калашникова и Петрова"
        description="Карабин Калашникова-Петрова был назван удачным образцом, но не смог пройти испытания в затрудненных условиях. Тогда М.Т. Калашников получил главный урок о необходимости создавать сверхнадежное оружие"
      />
      <Content back="/cabinet/">
        <h1>Карабин Калашникова и&nbsp;Петрова</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img
            fluid={kalash}
            alt="Карабин Калашникова и Петрова"
            style={{ maxWidth: 656 }}
          />
          <p>
            В&nbsp;1944 году Калашников принял участие в&nbsp;конкурсе
            на&nbsp;создание самозарядного карабина под новый промежуточный
            патрон образца 43&nbsp;года. Следует отметить, что в&nbsp;тот момент
            система вооружения армии еще прорабатывалась. Одним
            из&nbsp;вариантов было вооружение пехоты самозарядными карабинами,
            дополненными тяжелыми автоматами.
          </p>
          <p>
            Михаилу Калашникову совместно с&nbsp;еще одним сотрудником
            стрелкового полигона удалось разработать достаточно удачный образец.
            При этом часть решений была взята у&nbsp;американской винтовки
            Гаранда, показавшей высокую надежность. Кроме того, как отмечено
            в&nbsp;отчете об&nbsp;испытаниях, некоторые решения ранее уже
            использовались на&nbsp;советских экспериментальных винтовках 30-х
            годов, с&nbsp;которыми Калашников также мог ознакомиться.
          </p>

          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2nizmdyg6ww0a47rbrzvk93"
            title="Образец из коллекции Военно-исторического музея артиллерии, инженерных войск и войск связи"
            margin
          />

          <p>
            В&nbsp;ходе испытаний карабин был признан &laquo;удобным при
            стрельбе из&nbsp;всех положений&raquo;. Однако образец Калашникова
            и&nbsp;Петрова все&nbsp;же не&nbsp;уложился в требования ГАУ,
            в&nbsp;частности, по&nbsp;весу, кучности на&nbsp;дистанции 300
            метров и&nbsp;ряду других параметров. Решающим&nbsp;же оказался один
            из&nbsp;этапов испытания стрельбой в&nbsp;затрудненных условиях:
            после 10&nbsp;минут замачивания в&nbsp;болотной воде СККП перестал
            стрелять.
          </p>
          <p>
            Учитывая, что к&nbsp;этому моменту конструктором Симоновым был
            разработан более удачный вариант самозарядного карабина,
            впоследствии принятого на&nbsp;вооружение как СКС, доработка образца
            Калашникова была сочтена нецелесообразной. Главным результатом
            проделанной работы стал полученный Михаилом Тимофеевичем урок
            о&nbsp;важности обеспечения надежной работы оружия в&nbsp;любых
            условиях.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q66hb5i7ml0a47iz6eto04"
            margin
          />
        </div>
      </Content>
    </>
  )
}

export default MapPage
