import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Посещение ЮАР | Калашников100"
        description="Михаил Тимофеевич Калашников всегда узнавал устройство своего автомата в образцах иностранного вооружения. Так случилось и на заводе Vektor во время посещения ЮАР"
      />
      <Content back="/map/">
        <h1>Южная Африка</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q1ysy309pa0947mkq1rnpn"
            title="Андрей Вишняков, начальник отдела протокола"
            margin
          />

          <p>
            &mdash;&nbsp;Михаил Тимофеевич любил путешествовать. Я&nbsp;всегда
            удивлялся, как он&nbsp;в таком возрасте... Ведь, например, поездка
            в&nbsp;Южную Африку&nbsp;&mdash; это далекая поездка, длительный
            перелет!
          </p>

          <p>
            У&nbsp;нас был очень большой друг&nbsp;&mdash; господин Фред Тато.
            Это самый первый дистрибьютор нашего оружия в&nbsp;ЮАР.
            А&nbsp;мы&nbsp;тогда как раз пытались в&nbsp;Южной Африке создать
            определенную структуру, которая&nbsp;бы позволила нам успешно
            продавать продукцию нашего предприятия на&nbsp;целый континент.
            Объем работы был большой, и&nbsp;мне посчастливилось там работать.
          </p>

          <p>
            Поступила задача: едет Михаил Тимофеевич, его надо встречать, наши
            друзья из&nbsp;Кейптауна готовят встречу, бывший и&nbsp;действующий
            консулы нашей страны, представители ЮАР, посольство... Встретили
            Михаила Тимофеевича, представил его нашему послу, военному атташе,
            все как положено. Замечательная, душевнейшая встреча&nbsp;&mdash;
            и&nbsp;потом мы&nbsp;все вместе такой большой группой поехали
            на&nbsp;место размещения. Там очень уютный частный отель, который
            находился немного в&nbsp;стороне от&nbsp;больших гостиниц и&nbsp;был
            под охраной спецструктур, которые как раз обеспечивали безопасность
            Калашникова.
          </p>

          <p>
            И&nbsp;самое поразительное, что Михаил Тимофеевич, после такого
            долго перелета, едва прибыв на&nbsp;место, сразу спросил:
            &laquo;А&nbsp;я&nbsp;могу здесь искупаться?&raquo;. А&nbsp;там был
            бассейн, с&nbsp;кристально чистой водой. И&nbsp;Михаил Тимофеевич
            на&nbsp;глазах наших изумленных коллег тут&nbsp;же пустился плавать
            в бассейне. Это доставляло ему огромное удовольствие.
          </p>

          <p>
            Потом, конечно, было огромное количество встреч.
            Мы&nbsp;организовали выставку с&nbsp;презентацией нашего оружия.
            Михаил Тимофеевич там был самым почетным гостем, конечно, как всегда
            нарасхват. Свозили его на&nbsp;завод &laquo;Vektor&raquo;, который
            производил стрелковое оружие. &laquo;Так это&nbsp;же мой автомат,
            только переделанный! Хорошо, тюнинг хороший сделан...&raquo; (ну,
            он&nbsp;использовал другое слово, &laquo;тюнинг&raquo;&nbsp;&mdash;
            это уже современное). Потом Михаил Тимофеевич отправился
            в&nbsp;Кейптаун, там, к&nbsp;сожалению, мне уже не суждено было его
            сопровождать.
          </p>

          <p>
            Но&nbsp;там он&nbsp;побывал на&nbsp;охоте, посетил Мыс Доброй
            Надежды, другие такие выдающиеся места. Он&nbsp;отразил в&nbsp;своих
            мемуарах эту поездку. Там невообразимой красоты природа, кто
            был&nbsp;&mdash; подтвердит. И&nbsp;Михаил Тимофеевич, конечно,
            надолго это запомнил.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
