import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Blueprint.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Калашников как конструктор | Калашников10" description="" />
      <Content back="/table/">
        <h1>Калашников как конструктор</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img
            fluid={kalash}
            alt="Калашников как конструктор"
            style={{ maxWidth: 656 }}
          />
          <p>
            Уже самые ранние работы Михаила Тимофеевича наглядно демонстрировали
            его потенциал конструктора. Например, счетчик моточасов был создан в
            полковых мастерских фактически из подручных материалов. А доступно в
            танковом полку было не так уж и много.{" "}
          </p>

          <p>
            Конструкция его первого пистолета-пулемета была признана, как
            минимум, интересной. Уже второй образец работы Калашникова – ручной
            пулемет – хоть и не смог пройти испытания, тем не менее вызвал
            интерес у офицеров полигона, которые так отзывались о конструкции:
            «отдельные узлы и механизмы имеют некоторую новизну и могут быть
            рекомендованы для использования при конструировании новых опытных
            образцов автоматического оружия». Для второго образца начинающего
            конструктора это можно считать очень высокой оценкой.
          </p>
          <p>
            При этом можно достаточно уверенно сказать, что именно НИПСВО КА
            (Научно-исследовательский полигон стрелкового вооружения Красной
            армии) оказал очень важную роль в окончательном формировании
            Калашникова как оружейного конструктора. Именно опыт работы и
            прохождения испытаний там, пусть и неудачный на первых порах, помог
            ему осознать, что любая новизна и оригинальность конструкции не
            должны подменять собой такие важные параметры армейского вооружения,
            как надежность, технологичность и простоту эксплуатации. На НИПСВО
            молодой конструктор получил возможность ознакомиться с уже
            созданными советскими и иностранными образцами вооружения, включая
            опытные экземпляры, а также и получить советы более опытных коллег.
            Большое влияние на работы Калашникова оказал стиль Алексея Судаева,
            являвшегося ярким представителем советской оружейно-конструкторской
            школы. В итоге именно АК стал наиболее известным символом этого
            подхода к созданию стрелкового оружия.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
