import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    kalash: {
      childImageSharp: { fluid: kalash },
    },
    itemTest: {
      childImageSharp: { fluid: itemTest },
    },
    itemTest2: {
      childImageSharp: { fluid: itemTest2 },
    },
  } = useStaticQuery(graphql`
    query {
      kalash: file(relativePath: { eq: "content/AK-47_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemTest: file(relativePath: { eq: "content/item-test.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      itemTest2: file(relativePath: { eq: "content/item-test-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="АК-47 | Калашников100"
        description="Автомат Калашникова АК-47 стал крупным достижением в области стрелкового оружия и был принят на вооружение многих стран мира, что сделало его одним из самых узнаваемых символов XX века"
      />
      <Content back="/cabinet/">
        <h1>АК-47</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="АК-47" style={{ maxWidth: 656 }} />
          <p>
            К&nbsp;началу 1945 года у&nbsp;СССР уже было два удачных образца
            стрелкового оружия под промежуточный патрон 43&nbsp;года
            7,62&times;39&nbsp;мм&nbsp;&mdash; самозарядный карабин Симонова СКС
            и&nbsp;автомат Судаева АС-44. Но&nbsp;обстановка на&nbsp;фронте уже
            не&nbsp;требовала немедленного внедрения нового вооружения в
            производство. В&nbsp;этих условиях руководство ГАУ КА&nbsp;приняло
            решение провести работы по&nbsp;созданию нового, более легкого
            варианта автомата, который мог&nbsp;бы стать основным оружием для
            бойцов.
          </p>
          <p>
            После первого этапа конкурса из&nbsp;представленных образцов были
            отобраны три наиболее удачно проявивших себя автомата&nbsp;&mdash;
            Булкина, Дементьева и Калашникова.
          </p>
          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8etckk348854kmmm1dfwmsir"
            title="Образец из коллекции Военно-исторического музея артиллерии, инженерных войск и войск связи"
            margin
          />

          <p>
            В&nbsp;ходе подготовки к&nbsp;финальному этапу Михаил Калашников при
            помощи специалистов ковровского завода
            &#8470;&nbsp;2&nbsp;переработал свой образец наиболее полно, учтя
            замечания испытателей полигона. В&nbsp;итоге на&nbsp;заключительном
            испытании конкурса в&nbsp;1947 году автоматы конструкции Калашникова
            показали себя наиболее надежными.
          </p>
          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8f089n430790kmmmpyvm8eca"
            title="Образец из коллекции Военно-исторического музея артиллерии, инженерных войск и войск связи"
            margin
          />

          <p>
            Хотя при этом&nbsp;АК не&nbsp;смог выполнить некоторые требования
            по&nbsp;кучности стрельбы очередями, на&nbsp;итоговом совещании
            офицеров полигона и представителей Главного артиллерийского
            управления с&nbsp;оглядкой на военный опыт было решено, что
            надежность оружия должна считаться наиболее важным параметром.
            В&nbsp;итоге автомат конструкции Михаила Калашникова был
            рекомендован для принятия на&nbsp;вооружение и&nbsp;принят в 1949
            году как &laquo;7,62 автомат Калашникова&raquo; (АК). Также
            в&nbsp;ряде документов встречается название АК-47, которое позднее
            стали применять к автоматам первых серий, чтобы отличить
            их&nbsp;от&nbsp;более поздних вариантов &mdash; АКМ, АК-74
            и&nbsp;других.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p48qqxhc160a47lyayyuni"
            margin
          />

          <p>
            Принятие на&nbsp;вооружение&nbsp;АК для СССР стало большим
            достижением в&nbsp;области стрелкового вооружения, определившем
            развитие этого вида оружия более чем на&nbsp;полвека. Автомат был
            принят на&nbsp;вооружение десятков стран, став одним
            из&nbsp;наиболее ярких и&nbsp;узнаваемых символов второй половины
            XX&nbsp;века.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
