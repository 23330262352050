import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Командировки на Кубу | Калашников100"
        description="Поездка на Кубу с М.Т. Калашниковым во главе делегации с целью подписания контракта на поставку автоматов открыла конструктора с новой стороны"
      />
      <Content back="/map/">
        <h1>Куба</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p4j1wmhcq60a474cmuz2j8"
            title="Николай Швец, полковник запаса, доктор экономических наук (в
              1998-2007&nbsp;гг работал в&nbsp;системе военно-технического
              сотрудничества&nbsp;РФ с&nbsp;иностранными государствами)"
            margin
          />

          <blockquote>
            &nbsp;Одна из&nbsp;первых командировок была на&nbsp;Кубу. В&nbsp;это
            время в &laquo;Рособоронэкспорте&raquo; была получена заявка
            на&nbsp;поставку партии автоматов Калашникова с&nbsp;подствольным
            гранатометом для научно-исследовательского института Кубы. Михаилу
            Тимофеевичу была предоставлена доверенность для подписания этого
            контракта во&nbsp;время пребывания на&nbsp;Кубе. Михаил Тимофеевич
            это с&nbsp;честью выполнил.
          </blockquote>
          <br />
          <h2>
            <b>
              Подписание контракта, диалог с&nbsp;начальником Генерального штаба
            </b>
          </h2>

          <p>
            На&nbsp;Кубе было много встреч с&nbsp;государственными
            и&nbsp;политическими деятелями, были встречи с&nbsp;военными
            <br />
            коллективами в&nbsp;военных частях. Одной из&nbsp;самых важных
            встреч была встреча с&nbsp;начальником Генерального штаба
            революционных сил Кубы&nbsp;&mdash; генералом корпуса,
            с&nbsp;которым обсуждались вопросы, связанные с&nbsp;развитием
            военно-технического сотрудничества между Россией и&nbsp;Кубой.
            Обсуждались в&nbsp;том числе и современные политические
            и&nbsp;исторические вопросы.
          </p>
          <p>
            Интересно было наблюдать, как Михаил Тимофеевич выстраивал диалог и
            разговор с&nbsp;начальником Генерального штаба. Учитывая, что
            он&nbsp;говорил на испанском языке, а&nbsp;Михаил Тимофеевич
            на&nbsp;русском, в&nbsp;один момент Михаил Тимофеевич сказал:
            &bdquo;Можно я&nbsp;к&nbsp;Вам обращусь с&nbsp;просьбой?
            Вы&nbsp;знаете, я был в&nbsp;Китае, испытывал автоматы новой серии
            с&nbsp;более мелким калибром, учитывая, что выстрелы этих автоматов
            громкие, у&nbsp;меня стал плохим слух в&nbsp;правом ухе. Поэтому,
            уважаемый товарищ генерал, я&nbsp;Вас очень прошу - говорите громче.
            Мне так хочется слушать вашу приятную речь&ldquo;. На&nbsp;что
            начальник Генерального штаба&nbsp;&mdash; тоже человек
            с&nbsp;юмором, герой Кубы - ответил, что во&nbsp;время боевых
            действий он&nbsp;тоже был подвержен контузии, и&nbsp;если
            у&nbsp;Михаила Тимофеевича не&nbsp;слышало правое ухо,
            то&nbsp;у&nbsp;него не слышит левое ухо. И&nbsp;взаимно попросил,
            чтобы Михаил Тимофеевич на русском тоже говорил громче.
            В&nbsp;дальнейшем беседа протянулась более полутора часов.
            Подписание контракта взял на&nbsp;контроль начальник Генерального
            штаба, и&nbsp;через несколько часов
            в&nbsp;&bdquo;Техноимпорте&ldquo; свершилось историческое событие:
            Михаилом Тимофеевичем был подписан контракт на поставку партии
            автоматов.
          </p>
          <br />
          <h2>
            <b>Михаил Тимофеевич Калашников&nbsp;&mdash; бизнесмен</b>
          </h2>

          <p>
            Михаил Тимофеевич из&nbsp;Кубы возвращался очень довольный
            результатами деятельности, за&nbsp;исключением того, что
            не&nbsp;свершилась его мечта встретиться с&nbsp;Фиделем Кастро.
            Такая встреча прорабатывалась, но, к сожалению, из-за состояния
            здоровья команданте не&nbsp;смог принять Михаила Тимофеевича
            в&nbsp;эти дни. Но&nbsp;а&nbsp;все остальные мероприятия были
            выполнены полностью, контракт подписан, поэтому все возвращались
            в&nbsp;хорошем настроении.
          </p>
          <p>
            Михаил Тимофеевич летел в&nbsp;бизнес-классе, как всегда. К&nbsp;нам
            подошла Фаина Узбаковна и&nbsp;попросила делегацию из&nbsp;трех
            человек подойти к Михаилу Тимофеевичу. Мы, конечно, удивились,
            потому что шла глубокая ночь, три часа, летим над Атлантикой.
            Но&nbsp;тем не&nbsp;менее, когда мы подошли к&nbsp;Михаилу
            Тимофеевичу, то&nbsp;увидели на&nbsp;столике несколько рюмок с
            коньяком. Михаил Тимофеевич сказал: &laquo;Уважаемые коллеги,
            я&nbsp;возвращаюсь из&nbsp;Кубы с&nbsp;чувством выполненного долга.
            В&nbsp;предыдущих командировках и мероприятиях вы&nbsp;принимали
            участие вместе со&nbsp;мной как с&nbsp;генеральным конструктором
            стрелкового оружия, а&nbsp;сегодня мне хочется поделиться с вами
            моей радостью и&nbsp;сказать, что вы&nbsp;возвращаетесь из&nbsp;Кубы
            еще и&nbsp;с Михаилом Тимофеевичем Калашниковым&nbsp;&mdash;
            бизнесменом. Ведь я&nbsp;контракт подписал!&raquo;.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rn8nm80zwv0947zyt36tv7"
            title="Александр Ужанов, биограф М.Т. Калашникова"
            margin
          />

          <blockquote>
            Самой памятной и самой знаковой для меня была поездка в апреле 2006
            года на Остров Свободы. Калашникову было предложено возглавить
            официальную делегацию ФГУП “Рособоронэкспорт”. В таком качестве
            Михаил Тимофеевич был впервые.
          </blockquote>
          <br />
          <h2>
            <b>Подготовка к поездке</b>
          </h2>
          <p>
            Это была поездка политического значения, потому что 2006 - это год
            заключения контракта о поставке автоматов Калашникова в Венесуэлу.
            Главный конкурент России — США — очень ревностно отнеслись к этому
            контракту, и как это бывает обычно в системе продвижения вооружений,
            работа по выполнению контрактных обязательств сопровождалась мощным
            информационным фоном, противодействием, противоборством. Наша
            поездка тоже сопровождалась многими провокационными моментами, мы
            очень переживали, что поездка сорвется, поэтому тщательно готовились
            не только в маркетинговом плане, но шла еще и информационная
            наработка. Я вспоминаю брифинг, который был проведен в стенах
            “Рособоронэкспорта” с участием конструктора, о том, что контракт на
            самом деле по современным изделиям, по сотой версии автомата
            Калашникова. Статьи в зарубежной прессе носили фейковый характер,
            вброшена была информация о том, что под эгидой нового изделия в
            латиноамериканскую страну идет отправка старых автоматов, хранящихся
            еще с 50-х годов в арсеналах Минобороны. Это было совершенной
            неправдой и абсурдом. Все это мы развенчали, руководство делало
            заявления, также комментировал ситуацию и конструктор. На таком
            ажиотажном фоне мы отправились в дальнюю поездку.{" "}
          </p>
          <br />
          <h2>
            <b>События в самолете</b>
          </h2>
          <p>
            Возраст у Михаила Тимофеевича уже был солидный, конечно, мы
            переживали за состояние его здоровья. Но 20-ти часовой перелет был
            вполне приемлемый. Михаил Тимофеевич выдержал эту дорогу, был весел
            и жизнерадостен. Мы очень много беседовали в полете.{" "}
          </p>
          <p>
            Первая остановка была в Париже, потом дозаправка в Ливии. Интересный
            момент, ведь у него была переписка и личная встреча с Муаммаром
            Каддафи. В свое время он побывал в Триполи, был в штаб-квартире
            ливийского лидера, и когда мы заправлялись, на борт поступила
            телеграмма, в которой глава государства приветствовал конструктора
            на своей территории.{" "}
          </p>
          <p>
            Но а в Париже вообще случился такой неожиданный казус — французские
            спецслужбы приняли три сувенирных макета автомата Калашникова АК-74,
            которые глава делегации доставлял руководителям кубинского
            государства, за боевые изделия, из-за чего задержали самолет. Нам
            повезло, что в составе делегации была Татьяна Дмитриевна Швец,
            которая знала французский язык, она быстро перевела сертификаты и
            инструкции к сувениру, так удалось убедить секьюрити разрешить
            продолжить полет.{" "}
          </p>
          <br />
          <h2>
            <b>Подписание контракта</b>
          </h2>
          <p>
            Командировка была во всех смыслах результативная, самым главным ее
            достижением было формирование политического события, поддержавшего
            существенно поставку изделий на латиноамериканский рынок. Это
            придало значимость контракту, сформировало атмосферу доверия в мире
            в целом к российскому контракту. Хотя мы сильно его не афишировали,
            но были определенные брифинги и встречи с кубинскими журналистами, с
            иностранными журналистами, аккредитованными в Гаване. Конструктор
            давал интервью в российском посольстве. Но когда подписывался
            контракт, были созданы непубличные негласные условия, не было
            представителей прессы. Контракт подписывался с государственной
            компанией по импорту и экспорту продукции военного и двойного
            назначения “Техноимпорт”, в офисе этой компании. Мы переживали, что
            у Михаила Тимофеевича будут затруднения с тем, чтобы поставить
            подпись, потому что после слепого осколочного ранения, полученного в
            первые годы войны в 1941 году, у него были определенные проблемы с
            сухожилием. Он при подписании медленно двигал по контракту рукой,
            приговаривая: “Какая же длинная фамилия у конструктора, долго вывожу
            свою фамилию”. К счастью, все сложилось. Мне повезло, я сработал как
            представитель прессы, сделал снимок, который впоследствии обошел все
            средства массовой информации и лег в мою книгу в главу об этой
            поездке.{" "}
          </p>

          <br />
          <h2>
            <b>Официальные встречи на Кубе</b>
          </h2>
          <p>
            К большому сожалению, встреч на уровне главы государства не было, но
            были очень интересные встречи с команданте революции, соратником
            Фиделя и Рауля — Фриасом. Была встреча с первым кубинским
            космонавтом, который в должности начальника Управления внешних
            связей министерства обороны республики Куба обеспечивал всю поездку,
            сопровождал конструктора, организовывал встречи.{" "}
          </p>
          <p>
            Также была очень любопытная встреча с начальником Генерального
            штаба, заместителем министра обороны по вооружению и с кубинским
            конструктором стрелкового оружия, судьба которого сродни судьбе
            нашего российского самородка. Он тоже был кубинским крестьянином,
            который воевал в повстанческой армии Фиделя, участвовал в
            освободительном движении и так же - на коленках в партизанском
            отряде - разрабатывал образцы. Но в этом диалоге кубинский
            конструктор пришел к решению, что лучшим изделием является
            конструкция Калашникова. Поэтому автомат Калашникова прижился в
            вооруженных силах и силовых структурах Кубы.{" "}
          </p>
          <p>
            При техническом содействии Советского союза там было в 70-х годах
            начато строительство, и в начале 80-х был введен завод по
            производству автоматов системы Калашникова в городе Камагуэй. К
            сожалению, автоматы на нем так и не были выпущены, но в ходе поездки
            мы эту чувствительную сторону не затрагивали. Михаил Тимофеевич
            знал, что это болезненное место в наших отношениях. Не смогла
            кубинская сторона отработать все необходимые условия для запуска
            производства. Поэтому контракт на АК-103 в рамках этой поездки был
            подписан и поставлен на Кубу.{" "}
          </p>

          <br />
          <h2>
            <b>Кубинский колорит</b>
          </h2>
          <p>
            Помимо официальных встреч были, конечно, и пикантные интересные
            моменты, интересная программа предложена конструктору. Он и вся
            делегация располагались в Варадеро - это жемчужина Кубы, где
            расположено порядка 50 прибрежных отелей, в одном из них как раз
            располагался конструктор. Его отдых, его встречи, теплые приемы, все
            это придавало колорит этой поездке.{" "}
          </p>
          <p>
            Его поразило, что маленькая девочка-кубинка с завязанными глазами
            собирала автомат за 8-9 секунд, поставив рекорд, превзойдя результат
            взрослых военнослужащих. Он просил найти эту девочку. Переводчик
            Роман сказал, что она уже взрослая, ее зовут Изель. Мы все ждали
            этой встречи, но она не случилась.{" "}
          </p>
          <p>
            Но было много других интересных моментов, например, посещение
            фабрики по производству сигар Partagas. Легендарная фабрика,
            созданная еще в XIX веке. Мы ознакомились с технологией производства
            сигар, были и экзотические моменты, например, скатывание сигар на
            бедрах женщин. Об этом я писал в книге. Персонал из полуобнаженных
            женщин занимался производством, впоследствии это все
            трансформировалось, но элемент скатывания на обнаженной ноге был
            продемонстрирован.{" "}
          </p>

          <p>
            Нам чувствовалось, что Калашников в ходе поездки закладывает
            какую-то очередную свою книгу, поскольку он очень много шутил,
            делился воспоминаниями исторического плана. Мы предполагали, что у
            него рождается какая-то очередная книга, литературная идея.{" "}
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
