import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Выставки ОАЭ | Калашников100"
        description="Михаил Тимофеевич Калашников работал на выставке IDEX в ОАЭ, городе Абу-Даби, притягивая к себе внимание и шейхов, и прессы"
      />
      <Content back="/map/">
        <h1>ОАЭ</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q2g2n0i0rb0a474vqw2tc2"
            title="Андрей Вишняков, начальник отдела протокола"
            margin
          />

          <blockquote>
            &nbsp;Представители Рособоронэкспорта чаще ездили с&nbsp;Михаилом
            Тимофеевичем, но&nbsp;и&nbsp;мне посчастливилось побывать с&nbsp;ним
            на&nbsp;нескольких выставках и&nbsp;быть рядом. Например, IDEX
            в&nbsp;ОАЭ, город Абу-Даби. Это была одна из&nbsp;первых выставок,
            середина 90х, наверное.
          </blockquote>

          <p>
            Михаил Тимофеевич всегда был окружен вниманием и&nbsp;шейхов,
            и&nbsp;прессы. Все люди шли к&nbsp;нему, чтобы увидеть потрогать.
            &laquo;Это вы? Вы&nbsp;еще живы??&raquo;. Такие глупые вопросы
            задавали, да. Плюс многочисленные интервью... Всегда удивлялся, как
            Михаил Тимофеевич находит в&nbsp;себе такое количество энергии,
            чтобы отвечать на&nbsp;все эти вопросы, быть в&nbsp;форме,
            на&nbsp;ногах, и&nbsp;при этом блестяще выглядеть.
          </p>

          <p>
            Такой был случай на&nbsp;этой выставке. Абу-Даби был тогда совсем
            другим городом, достаточного количества гостиниц не&nbsp;было,
            и&nbsp;нам приходилось жить в&nbsp;Дубаи: не&nbsp;успели
            забронировать отель своевременно. И&nbsp;вот поездка
            в&nbsp;170&nbsp;километров... Иногда пролетаешь и&nbsp;приезжаешь
            раньше, а иногда попадаешь в&nbsp;какую-то пробку, непредвиденные
            обстоятельства&nbsp;&mdash; и чуть позднее. И&nbsp;однажды такая
            ситуация случилась: пробка абсолютно ничем
            не&nbsp;спровоцированная&nbsp;&mdash; в&nbsp;общем, приезжаем
            мы&nbsp;минут на&nbsp;30&nbsp;позднее, уже после открытия выставки.
            На&nbsp;нашем стенде уже стоит Михаил Тимофеевич и&nbsp;сурово
            на&nbsp;нас всех смотрит. &laquo;Вы&nbsp;почему опоздали?&raquo;.
            Мы&nbsp;все как школьники перед ними: виноваты, в&nbsp;пробку
            попали. &laquo;Так вы&nbsp;раньше выезжайте! И&nbsp;чтоб
            в&nbsp;последний раз такое, чтобы вы&nbsp;были, представители нашего
            легендарного завода, всегда в&nbsp;отличной форме и&nbsp;всегда на
            месте!&raquo;.
          </p>

          <p>
            Михаил Тимофеевич с&nbsp;точки зрения дисциплины был всегда
            требовательным и&nbsp;к&nbsp;себе, и&nbsp;от&nbsp;других требовал
            выполнения того&nbsp;же. Он&nbsp;с&nbsp;очень большой любовью
            относился, когда видел, что люди отдают себя, работают, всегда тепло
            встречал таких людей, общался с&nbsp;ними. Но&nbsp;не&nbsp;терпел
            ленность, инертность и&nbsp;т.д.
          </p>

          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p4cnjyhcaf0a47td4dcllv"
            title="Николай Швец, полковник запаса, доктор экономических наук (в
              1998-2007&nbsp;гг работал в&nbsp;системе военно-технического
              сотрудничества&nbsp;РФ с&nbsp;иностранными государствами)"
            margin
          />

          <blockquote>
            Во&nbsp;время выставки IDEX в&nbsp;Абу-Даби в&nbsp;Арабских Эмиратах
            в&nbsp;2003 году Михаил Тимофеевич работал на&nbsp;стенде
            &laquo;Рособоронэкспорта&raquo;.
          </blockquote>
          <p>
            У&nbsp;меня было отдельное задание от&nbsp;руководства компании,
            но&nbsp;при этом перед командировкой первый заместитель генерального
            директора &laquo;‎Рособоронэкспорта&raquo;‎ Сергей Викторович
            Чемезов попросил посмотреть, как организована деятельность
            конструктора. Учитывая, что Михаил Тимофеевич в&nbsp;это время уже
            был в&nbsp;возрасте, очень важно было, чтобы у него были нормальные
            условия для деятельности.
          </p>
          <p>
            Первая встреча состоялась вечером в&nbsp;гостинице,
            я&nbsp;представился Михаилу Тимофеевичу, уточнил, как у&nbsp;него
            дела на&nbsp;выставке, и&nbsp;он&nbsp;стал рассказывать, что день
            его рабочий загружен очень плотно&nbsp;&mdash; встречи идут
            со&nbsp;многими делегациями со&nbsp;многих стран мира. Люди
            интересуются жизнью и&nbsp;деятельностью конструктора,
            ну&nbsp;и&nbsp;в&nbsp;целом развитием производства стрелкового
            оружия в&nbsp;России. На&nbsp;мой вопрос Михаилу Тимофеевичу
            о&nbsp;том, как он&nbsp;обедает и&nbsp;есть&nbsp;ли какие-нибудь еще
            жизненные вопросы, он&nbsp;сказал: &laquo;Ну&nbsp;что&nbsp;Вы,
            у&nbsp;меня такой плотный график работы, что мне некогда обедать.
            Мне девочки дают чай, кофе во&nbsp;время встреч. Печенья очень много
            я&nbsp;ем, орехов&raquo;.
          </p>
          <p>
            Потом, вечером, уже после того, как мы&nbsp;с&nbsp;ним поужинали,
            я&nbsp;вижу, как Михаил Тимофеевич достает коробочку
            с&nbsp;лекарствами. В&nbsp;это время он&nbsp;уже принимал некоторые
            лекарства.
          </p>
          <ul>
            <li>
              <i>Вы&nbsp;лекарства вовремя принимаете, как положено?</i>
            </li>
            <li>
              <i>Да, бывает,&nbsp;</i>&mdash; говорит&nbsp;он,&nbsp;&mdash;{" "}
              <i>забываю.</i>
            </li>
          </ul>
          <p>
            Ну&nbsp;и&nbsp;потом уже совсем вечером, когда он&nbsp;увидел, что
            у&nbsp;меня был мобильный телефон, на&nbsp;который он&nbsp;несколько
            раз звонил мне по&nbsp;служебным вопросам, он&nbsp;стеснительно меня
            попросил:
          </p>
          <ul>
            <li>
              <i>
                Можно&nbsp;ли по&nbsp;Вашему телефону позвонить в&nbsp;Ижевск?
              </i>
            </li>

            <li>
              <i>Да, конечно можно,&nbsp;</i>&mdash;
              ответил&nbsp;я,&nbsp;&mdash;
              <i>пожалуйста.</i>
            </li>
          </ul>
          <p>
            Набрал номер телефона, передал ему трубку, и&nbsp;слышу, как
            он&nbsp;говорит: &laquo;Фая, здравствуй, дорогая, это я&nbsp;&mdash;
            Михаил Тимофеевич. Я&nbsp;сейчас нахожусь в&nbsp;командировке, очень
            много работаю, у&nbsp;меня все хорошо, и вообще, я&nbsp;здесь живу,
            как король. Не&nbsp;волнуйся, все у&nbsp;меня нормально&raquo;.
          </p>
          <p>
            В&nbsp;это время прозвучало имя Фаины Узбаковны,
            я&nbsp;поинтересовался у Михаила Тимофеевича, кто это.
            Он&nbsp;сказал, что это его жена, с&nbsp;которой они живут
            с&nbsp;82-го года, да&nbsp;и&nbsp;вообще его близкий, дорогой
            человек, поэтому он&nbsp;был очень благодарен, что ему представилась
            возможность сегодня с&nbsp;ней поговорить.
          </p>
          <p>
            С&nbsp;этого времени во&nbsp;все командировки направлялась Фаина
            Узбаковна, благодаря этому конструктор всегда находился
            в&nbsp;хорошем настроении, был всегда бодрый,
            ну&nbsp;и&nbsp;мы&nbsp;гораздо спокойнее чувствовали себя, ведь у
            Михаила Тимофеевича всё будет нормально.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
