import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Посещение Франции | Калашников100"
        description="Михаил Тимофеевич Калашников высоко ценил природные красоты мира. Ему довелось побывать на Лазурном берегу во Франции"
      />
      <Content back="/map/">
        <h1>Франция</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p4slcqhdnx0a47z4dx5zho"
            title="Николай Швец, полковник запаса, доктор экономических наук (в
              1998-2007&nbsp;гг работал в&nbsp;системе военно-технического
              сотрудничества&nbsp;РФ с&nbsp;иностранными государствами)"
            margin
          />

          <blockquote>
            &nbsp;Был 2006&nbsp;год, Михаил Тимофеевич участвовал
            в&nbsp;выставке, потом по приглашению администрации
            и&nbsp;общественных организаций, в&nbsp;том числе ветеранских,
            города Ниццы он&nbsp;посещал Лазурный Берег.
          </blockquote>
          <p>
            Михаил Тимофеевич любил ценить красивое и&nbsp;был очень рад встрече
            с этими местами. В&nbsp;один из&nbsp;вечеров, когда
            мы&nbsp;с&nbsp;ним прогуливались по Английской набережной,
            он&nbsp;сказал: &laquo;Да, действительно, какие&nbsp;же красивые
            места есть в&nbsp;мире, в&nbsp;том числе и&nbsp;Лазурный Берег.
            Если&nbsp;бы сюда не&nbsp;ходили поезда, не&nbsp;летали самолеты,
            зная, что есть такое место в мире, я&nbsp;бы сюда даже пешком
            пришел&raquo;.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
