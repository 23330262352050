import React, { useState } from "react"
import Img from "gatsby-image"
import Modal from "react-responsive-modal"

import Magnet from "../pointer/magnet"
import styles from "./imagePoints.module.css"
import bgStyles from "../background/background.module.css"
import ResponsiveVideo from "./responsiveVideo"

import CloseIcon from "../../svg/closeBlack.svg"

const ImagePoints = ({ image, alt, points }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  return (
    <div className={styles.wrap}>
      <div className={styles.image}>
        <Img fluid={image} alt={alt} />
        {!!points &&
          points.map(({ href, position, title }) => (
            <Magnet
              key={href}
              className={`${bgStyles.pointWrap} ${bgStyles.pointWrapVideo}`}
              innerClassName={bgStyles.magnetInner}
              style={{
                left: position[0] + "%",
                top: position[1] + "%",
              }}
              multiply={1.3}
            >
              <button
                className={`${bgStyles.point} ${bgStyles.videoPoint} js-hover js-stick`}
                onClick={() => {
                  setModalOpen(true)
                  setModalContent(<ResponsiveVideo href={href} title={title} />)
                }}
              ></button>
            </Magnet>
          ))}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false)
        }}
        center
        classNames={{
          overlay: "no-pointer",
          closeButton: "no-pointer",
        }}
        showCloseIcon={false}
        styles={{
          overlay: {},
          modal: {
            background: "none",
            padding: 0,
            maxWidth: "100%",
          },
        }}
      >
        <>
          {modalContent}
          <button className={styles.close}>
            <CloseIcon></CloseIcon>
          </button>
        </>
      </Modal>
    </div>
  )
}

export default ImagePoints
