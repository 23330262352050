import React from "react"
import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Михаил Тимофеевич и Уго Чавес | Калашников100"
        description="В поездке в Венесуэлу М.Т. Калашников встретил близкого себе по духу человека. Им оказался не кто иной, как Уго Чавес, с которым они в дальнейшем сохранили крепкую дружбу"
      />
      <Content back="/map/">
        <h1>Венесуэла</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q1zy57hzhc0a47yf3znrk8"
            title="Андрей Вишняков, начальник отдела протокола"
            margin
          />

          <blockquote>
            &nbsp;Проект Венесуэлы&nbsp;&mdash; тоже большая часть нашего
            предприятия. Тогда там приняли на&nbsp;вооружение наш автомат,
            легендарный АК-103. Мы&nbsp;как раз в этот момент участвовали
            в&nbsp;выставке, показывали нашу продукцию. Феноменально было:
            стояли очереди к&nbsp;нам на&nbsp;стенд, просто чтобы только
            потрогать оружие.
          </blockquote>

          <p>
            И&nbsp;отдельная история&nbsp;&mdash; дружба Калашникова
            с&nbsp;президентом Венесуэлы. Михаил Тимофеевич был
            в&nbsp;Венесуэле, там встретился с&nbsp;Уго Чавесом. Они оказались
            настолько близкими по&nbsp;духу, по&nbsp;характеру, что очень сильно
            подружились. Михаил Тимофеевич пригласил Чавеса посетить наш завод.
            И такой визит состоялся. Немного таких государственных деятелей
            других стран были у&nbsp;нас на&nbsp;заводе. И&nbsp;Михаил
            Тимофеевич и&nbsp;Уго Чавес&nbsp;&mdash; как старые добрые друзья,
            тепло обнимались, могли часами говорить друг с другом.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
