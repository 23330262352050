import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Photo 5.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Детство конструктора | Калашников100"
        description="Детство будущего конструктора было прервано ссылкой из родного села в далекую Сибирь, откуда Калашникову помогла бежать врожденная техническая жилка "
      />
      <Content back="/painting/">
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <h1>
            Мемориал на&nbsp;месте дома, где прошло детство
            М.&nbsp;Т.&nbsp;Калашникова
          </h1>
          <Img
            fluid={kalash}
            alt="Детство конструктора"
            style={{ maxWidth: 256 }}
          />
          <p>
            Михаил Тимофеевич Калашников родился 10&nbsp;ноября 1919 года
            в&nbsp;селе Курья Барнаульского уезда Алтайской губернии.
            Он&nbsp;был од&shy;ним из девятнадцати детей. О&nbsp;раннем периоде
            своей жизни Калашников долгое время старался лишний раз
            не&nbsp;упоминать. Первый вариант его мемуаров
            &mdash;&nbsp;&laquo;Записки
            конструктора-оружейника&raquo;&nbsp;&mdash; начинается уже
            с&nbsp;описания службы в&nbsp;армии. Лишь в&nbsp;поздних изданиях
            книги, уже после 1997&nbsp;года, появляется информация
            о&nbsp;детстве.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2oq0onxgvuf0a47p6736iri"
            title="Детство конструктора"
            margin
          />

          <p>
            Причина этого кроется в&nbsp;лихом детстве будущего конструктора.
            В&nbsp;1930 году семья Калашниковых попала в&nbsp;списки
            раскулачиваемых и&nbsp;была переселена из&nbsp;родной Курьи
            в&nbsp;далекую сибирскую деревню Нижняя Моховая.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rno2h000gm0a47owp4xb4f"
            margin
          />

          <p>
            Там Калашников прожил несколько лет, мечтая вернуться в&nbsp;родную
            Курью. Изменить свое положение с&nbsp;ссыльнопоселенца
            на&nbsp;человека с паспортом Михаилу Калашникову уже тогда помогла
            техническая жилка. Внимательно изучив оттиск штампа комендатуры,
            он&nbsp;сумел подделать его, смастерив собственную печать
            из&nbsp;нароста на&nbsp;дереве.
          </p>

          <p>
            По&nbsp;фальшивым документам Михаил смог вернуться на&nbsp;родину,
            получить временные паспорта и&nbsp;устроиться работать
            на&nbsp;машинно-тракторную станцию. Однако уже через несколько
            месяцев произошло событие, которое изменило жизнь Калашникова
            навсегда. Друг показал ему старый пистолет браунинг, который
            хранился в&nbsp;Курье несколько десятков лет. Это было первое
            соприкосновение будущего великого конструктора с оружием. Пистолет
            не&nbsp;работал, и&nbsp;Калашников несколько дней разбирал и собирал
            его, надеясь починить. Так родился Калашников-конструктор.
          </p>
          <p>
            Позже милиции стало известно о&nbsp;хранящемся у&nbsp;молодого
            человека пистолете. Чтобы избежать ареста, пришлось снова уезжать
            из&nbsp;родных мест.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
