import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Посещение Индонезии | Калашников100"
        description="Одно только имя Михаила Тимофеевича Калашникова, прозвучавшее на выставке в Индонезии, заставило многочисленную делегацию во главе с премьер-министром отклониться от заданного маршрута"
      />
      <Content back="/map/">
        <h1>Индонезия</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q1s72ihywi0a4744pyj6zl"
            title="Андрей Вишняков, начальник отдела протокола"
            margin
          />

          <blockquote>
            &nbsp;На&nbsp;выставке в&nbsp;Индонезии приходит официальная
            делегация из&nbsp;ста человек. Наш стенд абсолютно не&nbsp;входит
            в&nbsp;планы этой делегации. А&nbsp;у меня было официальное
            поручение Михаила Тимофеевича: &laquo;Передай, пожалуйста, книгу
            самому выдающемуся человеку&raquo;. А&nbsp;кто самый выдающийся
            человек в&nbsp;то&nbsp;время? Конечно, это премьер-министр,
            руководитель страны. И&nbsp;как я&nbsp;могу не&nbsp;выполнить
            поручение Михаила Тимофеевича?
          </blockquote>

          <p>
            Одну книгу вручил руководителю бригад, генералу внутренних войск,
            они пользователи нашей продукции, любят ее&nbsp;и&nbsp;высоко несут
            знамя нашего легендарного автомата. А&nbsp;тут идет руководитель
            страны&nbsp;&mdash; и&nbsp;я, переступив границы протокольных норм
            и&nbsp;этики, выхожу ему навстречу и говорю:
          </p>

          <p>
            Уважаемый господин премьер-министр, простите, пожалуйста,
            но&nbsp;я&nbsp;не&nbsp;могу не&nbsp;исполнить поручение нашего
            выдающегося конструктора Михаила Тимофеевича Калашникова
            и&nbsp;не&nbsp;вручить Вам его книгу.
          </p>

          <ul>
            <li>
              <i>
                Калашников? А&nbsp;где Калашников? Почему мне никто
                не&nbsp;рассказал??
              </i>
            </li>
          </ul>

          <p>
            И&nbsp;вся делегация разворачивается и&nbsp;подходит к&nbsp;нашему
            стенду. Вот оно счастье! Мы&nbsp;рассказываем, показываем продукцию,
            глава страны берет автомат в&nbsp;руки&nbsp;и, конечно&nbsp;же,
            принимает книгу с&nbsp;великими словами благодарности
            от&nbsp;Михаила Тимофеевича&nbsp;и, соответственно, передает ему
            обратный привет. Я&nbsp;приехал и&nbsp;рассказал эту историю Михаилу
            Тимофеевичу. &laquo;Ну, ты&nbsp;молодец!&raquo;.
          </p>

          <p>
            Было очень приятно. Думаю, нельзя было не&nbsp;пригласить делегацию
            к нашему стенду. Видимо, те, кто выстраивает маршрут,
            не&nbsp;сообщили премьеру о&nbsp;нашем присутствии. Тем
            не&nbsp;менее, 2-3 минуты протокольного внимания произвели
            определенный фурор.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
