import React from "react"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  return (
    <>
      <SEO
        title="Посещение Индии | Калашников100"
        description="Как прошел теплый прием в доме отца индийского кино. М.Т. Калашников: «Индия — моя любимая страна, почти как моя Родина»"
      />
      <Content back="/map/">
        <h1>Индия</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q2dio509qm0947h7pecqtl"
            title="Андрей Вишняков, начальник отдела протокола"
            margin
          />

          <blockquote>
            &nbsp;Михаила Тимофеевича пригласила семья Радж Капура. Это
            известный актер, режиссер и&nbsp;основатель индийской империи
            фильмов, Болливуда, создал множество фильмов, которые
            в&nbsp;Советском Союзе смотрели, конечно же, с&nbsp;огромным
            удовольствием. Не&nbsp;сомневаюсь, что и&nbsp;Михаил Тимофеевич эти
            фильмы прекрасно знал, знал эти их&nbsp;песни. И&nbsp;тут такая
            возможность &mdash;&nbsp;побывать в&nbsp;семье Радж Капура. Сам Радж
            Капур, конечно, уже умер, но его сын&nbsp;&mdash; это точная копия
            папы! Тем более, его родственники породнились с&nbsp;семьей Нанда,
            а&nbsp;это люди, которые владеют огромным количеством предприятий,
            фабрик и&nbsp;т.&nbsp;д. В&nbsp;общем, визит был организован
            на&nbsp;высшем уровне, была прекрасная встреча, и&nbsp;дружеский
            ужин, и&nbsp;даже небольшой концерт, и&nbsp;мелодии
            и&nbsp;воспоминания из&nbsp;фильмов Капура.
          </blockquote>

          <p>
            Михаил Тимофеевич сам потом вспоминал, как тепло его принимали в
            Индии. Всегда говорил:{" "}
          </p>
          <ul>
            <li>
              {" "}
              <i>&laquo;Индия&nbsp;&mdash; моя любимая страна&raquo;. </i>
            </li>
          </ul>

          <p>Почти, говорит, как моя Родина.</p>

          <p>
            Так душевно это все было, брали в&nbsp;руки гитару, пели песни,
            и&nbsp;русские, и&nbsp;индийские. Когда у&nbsp;Капуров были
            танцы&nbsp;&mdash; это надо было видеть! Хороводом ходили под песни
            из&nbsp;индийских фильмов. Это, конечно, незабываемо; такие моменты
            в&nbsp;жизни, когда все были счастливы.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
