import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/AK-74.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="АК-74 | Калашников100"
        description="Работы над улучшением показателей кучности стрельбы в СССР не прекращались. Так появился автомат Калашникова АК-74 под новый малоимпульсный патрон 5,45×39 мм "
      />
      <Content back="/cabinet/">
        <h1>АК-74</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="AK-74" style={{ maxWidth: 656 }} />

          <p>
            Хотя по&nbsp;надежности и&nbsp;ряду других боевых показателей
            автомат Калашникова полностью устраивал военных, работы над более
            совершенными образцами стрелкового вооружения в&nbsp;СССР
            не&nbsp;прекращались. В&nbsp;частности, конструкторов ориентировали
            на&nbsp;достижение лучшей кучности автоматического огня при стрельбе
            из&nbsp;неустойчивых положений.
          </p>
          <p>
            Как показали проведенные исследования, частично эти проблемы были
            связаны с&nbsp;патроном образца 1943&nbsp;года. Он&nbsp;создавался
            в&nbsp;годы войны, в порядке срочного ответа на&nbsp;немецкий
            промежуточный патрон 7,92&times;33&nbsp;мм, и ряд примененных
            в&nbsp;нем решений в&nbsp;послевоенные годы уже не&nbsp;считался
            оптимальным. Долгое время решить проблему кучности никому не
            удавалось.
          </p>

          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8etja5351070kmmmnv4nc7vo"
            title="AK-74"
            margin
          />

          <p>
            В&nbsp;50-60-х годах в&nbsp;СССР были проведены работы
            по&nbsp;созданию нового единого автоматно-винтовочного патрона
            по&nbsp;типу 7,62&times;51&nbsp;мм НАТО. Однако наиболее
            перспективным стало создание малоимпульсного патрона 5,45&times;39
            мм&nbsp;и&nbsp;оружия под него. После длительных испытаний
            в&nbsp;финале остались автомат СА-006&nbsp;Константинова,
            использующий схему сбалансированной автоматики, и&nbsp;автомат
            А-3&nbsp;на основе более ранней конструкции Калашникова. Хотя
            автомат Константинова и&nbsp;продемонстрировал улучшение кучности
            стрельбы, это было сочтено недостаточным преимуществом для перехода
            на&nbsp;новую систему. Образец Калашникова имел технологическую
            преемственность с&nbsp;уже производимым и&nbsp;освоенным
            в&nbsp;армии автоматом, на вооружение под новый патрон был принят
            именно его вариант, ставший 5,45&nbsp;мм автоматом Калашникова
            образца 1974&nbsp;года.
          </p>

          <p>
            В&nbsp;начале 90-ых была произведена масштабная модернизация
            автомата, в результате которой на&nbsp;вооружение был принят АК-74М,
            который сочетал в себе лучшие качества более ранних версий.
            В&nbsp;отличие от&nbsp;своего предшественника он&nbsp;обладает
            складным прикладом и&nbsp;универсальным креплением для оптических
            прицелов. Корпус нового автомата выполнен из ударопрочного
            реактопласта, что позволило отказаться от&nbsp;деревянных элементов.
            Сам Михаил Калашников не&nbsp;принимал активного участия в
            разработке, но&nbsp;курировал ход проекта.
          </p>

          <p>
            АК-74М до&nbsp;сих пор является основным автоматом Вооруженных сил
            Российской Федерации. В&nbsp;ближайшие годы его постепенно заменит
            новейший автомат АК-12.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
