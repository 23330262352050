import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
    placeholderBook1: {
      childImageSharp: { fluid: placeholderBook1 },
    },
    placeholderBook2: {
      childImageSharp: { fluid: placeholderBook2 },
    },
    placeholderBook3: {
      childImageSharp: { fluid: placeholderBook3 },
    },
    placeholderBook4: {
      childImageSharp: { fluid: placeholderBook4 },
    },
    placeholderBook5: {
      childImageSharp: { fluid: placeholderBook5 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Book.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderBook1: file(relativePath: { eq: "content/book/Book_01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderBook2: file(relativePath: { eq: "content/book/Book_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderBook3: file(relativePath: { eq: "content/book/Book_03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderBook4: file(relativePath: { eq: "content/book/Book_04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderBook5: file(relativePath: { eq: "content/book/Book_05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Книга «Калашников: Эволюция» | Калашников100"
        description="В честь семидесятилетия принятия на вооружение легендарного автомата Калашникова редакция Калашников Media выпустила фотокнигу об истории АК"
      />
      <Content back="/table/">
        <h1>Книга «Калашников: Эволюция»</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img fluid={kalash} alt="Эволюция" style={{ maxWidth: 356 }} />

          <ResponsiveVideo
            href="https://kalashnikovgroup.ru/embed/ckt8et1cy345166kmmmrazjv4ir"
            margin
          />

          <p>
            В&nbsp;честь семидесятилетия принятия на&nbsp;вооружение
            легендарного автомата Михаила Калашникова редакция Калашников Media
            выпустила фотокнигу об истории&nbsp;АК, которая вобрала в&nbsp;себя
            все этапы его жизни&nbsp;&mdash; от&nbsp;первых прототипов
            до&nbsp;новейших АК-12/АК-15. Каждая модель снабжена техническими
            характеристиками и&nbsp;подробными иллюстрациями.
          </p>
          <h2>
            <center>Откройте для себя историю АК!</center>
          </h2>
          <br />
          <br />
        </div>
        <Img
          fluid={placeholderBook1}
          alt="Книга «Калашников: Эволюция» - Страница 1"
        />
        <Img
          fluid={placeholderBook2}
          alt="Книга «Калашников: Эволюция» - Страница 2"
        />
        <Img
          fluid={placeholderBook3}
          alt="Книга «Калашников: Эволюция» - Страница 3"
        />
        <Img
          fluid={placeholderBook4}
          alt="Книга «Калашников: Эволюция» - Страница 4"
        />
        <Img
          fluid={placeholderBook5}
          alt="Книга «Калашников: Эволюция» - Страница 5"
        />
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <br />

          <br />
          <br />
          <br />
          <center>
            <a
              class="utils-module--button--1WrY4 utils-module--buttonBlack--2leFB js-hover"
              href="https://shop.kalashnikov.com/card/fotokniga_-kalashnikov-evolyutsiya"
              target="_blank"
            >
              Купить книгу в магазине «Калашников»
            </a>
          </center>
          <br />
          <br />
        </div>
      </Content>
    </>
  )
}

export default MapPage
