import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ImagePoints from "../../components/content/imagePoints"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Book.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Ключи от машины"
        description="Подаренные когда-то Михаилу Тимофеевичу Калашникову автомобили можно найти в музеях страны. Рассказываем, какую марку он приобрел для себя сам"
      />
      <Content back="/table/">
        <h1>Ключи от машины</h1>

        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q5ztpb0a2a0947b84qfeau"
            title="Сергей Горбунов, ведущий инженер-конструктор"
            margin
          />

          <blockquote>
            &nbsp;Когда мы&nbsp;как-то раз поздравляли с&nbsp;днем рождения
            Михаила Тимофеевича, разговор зашел о&nbsp;благоустройстве города
            и&nbsp;городских улиц. Все, естественно, ругались, что асфальт
            плохой&nbsp;&mdash; проехать нельзя. На&nbsp;что Михаил Тимофеевич
            нам сказал: &laquo;Вы&nbsp;не&nbsp;видали, какие здесь улицы были
            раньше!&raquo;. И&nbsp;рассказал, что когда&nbsp;он, получив
            сталинскую премию, купил себе &laquo;Победу&raquo;, он&nbsp;пригнал
            ее&nbsp;сюда, в&nbsp;Ижевск и&nbsp;поехал домой. Тогда замощена или
            заасфальтирована была только часть улицы Советской, а&nbsp;ему нужно
            было повернуть на&nbsp;Пушкинскую и проехать еще два квартала вверх
            по&nbsp;ней до&nbsp;Бородина. Как только он съехал с&nbsp;Советской,
            он&nbsp;тут&nbsp;же всеми четырьмя колесами завяз в&nbsp;грязи.
            Машину не&nbsp;смог вытолкнуть ни&nbsp;сам, ни&nbsp;с&nbsp;помощью
            прохожих. В&nbsp;общем, ему пришлось возвращаться на&nbsp;завод
            и&nbsp;просить здесь тягач&nbsp;&mdash; грузовик. Они этим
            грузовиком выдернули &laquo;Победу&raquo; из&nbsp;грязи, потом
            увезли ее&nbsp;на завод и&nbsp;она здесь, бедная, стояла, пока
            дороги не&nbsp;просохли. После этого он&nbsp;стал на&nbsp;ней
            ездить.
          </blockquote>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2rmi7410z3y0947im2u1eps"
            title="Валерий Крылов, директор Военно-исторического музея артиллерии"
            margin
          />

          <blockquote>
            &nbsp;Стоит &laquo;УАЗик&raquo;, подаренный ему Павлом Сергеевичем
            Грачевым. Я&nbsp;был на&nbsp;75-летии в&nbsp;Ижевске. Серийный УАЗик
            подарил ему Грачев&nbsp;&mdash; министр обороны. Михаил Тимофеевич
            немного на&nbsp;нем наездил&nbsp;&mdash; километров 300-400,
            наверное. И&nbsp;он&nbsp;у&nbsp;него простоял лет&nbsp;20, наверное,
            в&nbsp;гараже над смотровой ямой. Естественно, сами понимаете,
            машина не эксплуатируется, пришла в&nbsp;негодность. Я&nbsp;говорю:
          </blockquote>

          <ul>
            <li>
              <i>
                &nbsp;Михаил Тимофеевич, наверное, надо этот &laquo;УАЗик&raquo;
                отправить в Санкт-Петербург
              </i>
            </li>
            <li>
              <i>
                &nbsp;Валерий Михайлович, но&nbsp;он&nbsp;в&nbsp;таком
                неприглядном состоянии.
              </i>
            </li>
          </ul>
          <p>
            Я&nbsp;очень благодарю одну из&nbsp;фирм по&nbsp;обслуживанию
            автомобилей за&nbsp;то, что они привели его в&nbsp;порядок.
            И&nbsp;вот мы&nbsp;на&nbsp;большом трейлере американском едем
            в&nbsp;Ижевск. Нас никто не&nbsp;остановил. И&nbsp;вот мы&nbsp;его
            погрузили, написали на&nbsp;нем &laquo;АК-47&raquo;. Когда
            мы&nbsp;ехали из&nbsp;Ижевска, я, правда не&nbsp;ехал, но&nbsp;мне
            докладывали мои подчиненные, что на&nbsp;каждом посту ГАИ
            останавливали трейлер для того, чтобы сотрудники инспекции могли
            сфотографироваться. Это было очень интересно.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
