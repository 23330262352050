import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
    placeholderImage2: {
      childImageSharp: { fluid: kalash2 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Timer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "content/Blueprint.png" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Конструкторское творчество | Калашников100"
        description="Врожденный талант Калашникова как конструктора, его способность прислушиваться к чужому мнению и внимательно работать над ошибками сделали его одним из самых ярких представителей советской оружейной школы"
      />
      <Content back="/office/">
        <h1>Конструкторское творчество</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <Img
            fluid={kalash2}
            alt="Калашников как конструктор"
            style={{ maxWidth: 656 }}
          />
          <br />
          <p>
            По&nbsp;воспоминаниям Михаила Тимофеевича, любовь к&nbsp;технике
            присутствовала у&nbsp;него с&nbsp;раннего возраста. Однако
            возможности для технического творчества в&nbsp;сибирской деревне
            были весьма ограничены: когда юный Миша Калашников захотел построить
            двигатель собственной конструкции, ему так и&nbsp;не&nbsp;удалось
            найти шарики для подшипника.
          </p>
          <br />
          <Img
            fluid={kalash}
            alt="Cчетчик моторесурса танка"
            style={{ maxWidth: 656 }}
          />
          <br />
          <p>
            Удивительно, но&nbsp;первое изобретение Калашникова выглядело вот
            так. Это счетчик моторесурса танка.
          </p>
          <p>
            Всерьез заняться техническим творчеством Калашников смог лишь после
            начала армейской службы. В&nbsp;танковых частях Киевского военного
            округа имелись и&nbsp;условия для этого, и&nbsp;&bdquo;спрос&ldquo;
            на&nbsp;рационализаторские предложения. Первой успешной конструкцией
            танкиста Калашникова стал счетчик моторесурса танка. После испытаний
            прототипа Калашников был вызван к&nbsp;Г.К. Жукову, командовавшему
            тогда Киевским особым военным округом.
          </p>

          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p4x57r09600947hlhwtt80"
            margin
          />
          <p>
            По&nbsp;его указанию в&nbsp;мастерских Киевского танкового училища
            были изготовлены еще два доработанных варианта прибора. Затем
            счетчик моточасов конструкции Калашникова был испытан
            на&nbsp;танковом полигоне Кубинка и&nbsp;по&nbsp;итогам испытаний
            рекомендован к&nbsp;серийному производству.
          </p>

          <p>
            Сам Калашников был командирован в&nbsp;Ленинград на&nbsp;завод
            &#8470;&nbsp;174, где параллельно с&nbsp;работой над счетчиком
            моточасов сконструировал также другой танковый прибор&nbsp;&mdash;
            выключатель массы, который сохранял заряд аккумулятора. Эта
            разработка прекрасно иллюстрировала будущие таланты Калашникова: она
            была проще и&nbsp;меньше аналогов. Изобретение было высоко оценено
            главным конструктором завода Семеном Гинзбургом, будущим создателем
            самоходки СУ-76.
          </p>

          <p>
            В&nbsp;этот момент Калашников имел все шансы продолжить свой
            творческий рост именно как танковый конструктор, но&nbsp;все резко
            изменилось после начала войны.
          </p>

          <p>
            Конструкция его первого пистолета-пулемета была признана, как
            минимум, интересной. Уже второй образец работы
            Калашникова&nbsp;&mdash; ручной пулемет&nbsp;&mdash; хоть
            и&nbsp;не&nbsp;смог пройти испытания, тем не&nbsp;менее вызвал
            интерес у&nbsp;офицеров полигона, которые так отзывались
            о&nbsp;конструкции: &laquo;отдельные узлы и&nbsp;механизмы имеют
            некоторую новизну и&nbsp;могут быть рекомендованы для использования
            при конструировании новых опытных образцов автоматического
            оружия&raquo;. Для второго образца начинающего конструктора это
            можно считать очень высокой оценкой.
          </p>

          <p>
            При этом можно достаточно уверенно сказать, что именно
            НИПСВО&nbsp;КА (Научно-исследовательский полигон стрелкового
            вооружения Красной армии) оказал очень важную роль
            в&nbsp;окончательном формировании Калашникова как оружейного
            конструктора. Именно опыт работы и прохождения испытаний там, пусть
            и&nbsp;неудачный на&nbsp;первых порах, помог ему осознать, что любая
            новизна и&nbsp;оригинальность конструкции не должны подменять собой
            такие важные параметры армейского вооружения, как надежность,
            технологичность и&nbsp;простоту эксплуатации. На&nbsp;НИПСВО молодой
            конструктор получил возможность ознакомиться с&nbsp;уже созданными
            советскими и&nbsp;иностранными образцами вооружения, включая опытные
            экземпляры, а&nbsp;также получить советы более опытных коллег.
            Большое влияние на&nbsp;работы Калашникова оказал стиль Алексея
            Судаева, являвшегося ярким представителем советской
            оружейно-конструкторской школы. В&nbsp;итоге именно&nbsp;АК стал
            наиболее известным символом этого подхода к&nbsp;созданию
            стрелкового оружия.
          </p>

          <p>
            При этом Михаил Тимофеевич был не&nbsp;только гениальным
            конструктором, но&nbsp;и&nbsp;отличным руководителем.
          </p>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q5s4oj0a120947al5qih8x"
            margin
          />
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q64kgz0a3q0947qxlxhop3"
            margin
          />

          <p>
            При этом важно отметить, что Калашников не&nbsp;утратил желания
            заниматься конструкторской деятельностью. Одно из&nbsp;его последних
            изобретений&nbsp;&mdash; отъемный ударно-спусковой механизм для
            карабина &laquo;Сайга&raquo;. Эта история наглядно демонстрирует,
            насколько в&nbsp;нем были развиты техническая интуиция
            и&nbsp;инженерное чутье.
          </p>

          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2q5y9shi6m10a47sqpwq5av"
            margin
          />
        </div>
      </Content>
    </>
  )
}

export default MapPage
