import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FacebookShareButton, VKShareButton } from "react-share"

import styles from "./about.module.css"
import utils from "../utils.module.css"

// import Insta from "../../svg/insta.svg"
import Vk from "../../svg/vk.svg"
import Fb from "../../svg/fb.svg"

const About = () => {
  const {
    kalash100: {
      childImageSharp: { fluid: kalash100 },
    },
    japar: {
      childImageSharp: { fluid: japar },
    },
  } = useStaticQuery(graphql`
    query {
      kalash100: file(relativePath: { eq: "kalash-100.png" }) {
        childImageSharp {
          fluid(maxWidth: 170) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      japar: file(relativePath: { eq: "content/japaridze.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.colContent}>
          <Img
            className={styles.kalash100}
            fluid={kalash100}
            alt="Михаил Тимофеевич Калашников"
            style={{ width: 170 }}
          />
          <h1>O проекте</h1>
          <p>
            &laquo;Калашников100&raquo;&nbsp;&mdash; мультимедийный спецпроект
            Калашников.Media, посвященный 100-летию со&nbsp;дня рождения
            великого конструктора Михаила Калашникова.
          </p>
          <p>
            Мы&nbsp;постарались воссоздать личный кабинет Михаила Тимофеевича, в
            котором его ближайшие друзья и&nbsp;коллеги расскажут не&nbsp;только
            про главные изобретения конструктора, но&nbsp;и&nbsp;про его жизнь.
          </p>
          <h2>Добро пожаловать!</h2>

          <h2 className={styles.shareTitle}>Поделиться с друзьями</h2>
          <div className={styles.share}>
            {/* <a
            className={styles.shareLink}
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            >
            <Insta />
          </a> */}
            <VKShareButton
              className={styles.shareLink}
              url="https://office.kalashnikov.media/"
            >
              <Vk />
            </VKShareButton>
            <FacebookShareButton
              className={styles.shareLink}
              url="https://office.kalashnikov.media/"
            >
              <Fb />
            </FacebookShareButton>
          </div>
          <p>
            <b>Главный редактор:</b> <br />
            Андрей Михеев
          </p>
          <p>
            <b>Редакторы:</b>
            <br /> Айла Белоусова, Екатерина Сапронова, Андрей Уланов, Эльдар
            Хансеидов
          </p>
          <p>
            <b>Операторы:</b>
            <br /> Дмитрий Гусев, Эмин Калантаров, Александр Кирпиченок, Валерий
            Мороз
          </p>
          <p>
            <b>Монтажеры:</b>
            <br /> Алексей Вахромов, Марина Дмитриенко
          </p>
          <p>
            <b>Фотограф:</b>
            <br /> Дмитрий Гусев{" "}
          </p>
          <p>
            <b>Дизайн, разработка и поддержка:</b>
            <br />
            <a href="https://kolibri.studio/" target="_blank">
              KLBR Studio
            </a>
          </p>
        </div>
        <div className={styles.colImg}>
          <figure className={styles.figure}>
            <Img
              fluid={japar}
              alt="Михаил Тимофеевич Калашников"
              style={{ maxWidth: 550 }}
            />
            <figcaption>
              <h3>Михаил Калашников</h3>
              <p>Фото: Миша Джапаридзе / AP</p>
            </figcaption>
          </figure>
        </div>
      </div>
      <button
        type="button"
        className={`${styles.back} ${utils.button} ${utils.buttonWhite} js-hover`}
        onClick={() => {
          window.history.back()
        }}
        // paintDrip
        // hex="#191919"
        // duration={0.45}
        // to={backLink}
      >
        ← Назад
      </button>
    </>
  )
}

export default About
