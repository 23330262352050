import React from "react"
import styles from "./responsiveVideo.module.css"

const ResponsiveVideo = ({ href, title, style, margin }) => {
  return (
    <div className={`${styles.container} ${margin ? ` ${styles.margin}` : ""}`}>
      <div className={`no-pointer ${styles.wrap}`}>
        <iframe
          className={styles.iframe}
          title={title || "Видео"}
          src={href}
          width="100%"
          height="420"
          allowFullScreen
          scrolling="no"
          frameBorder="0"
        />
      </div>
      {title && <h4 className={styles.title}>{title}</h4>}
    </div>
  )
}

export default ResponsiveVideo
