import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import utils from "../../components/utils.module.css"
import styles from "../../components/content/content.module.css"
import SEO from "../../components/layout/seo"
import Content from "../../components/content/content"
import ResponsiveVideo from "../../components/content/responsiveVideo"

const MapPage = () => {
  const {
    placeholderImage: {
      childImageSharp: { fluid: kalash },
    },
    placeholderImage2: {
      childImageSharp: { fluid: kalash2 },
    },
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "content/Gricenk_01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholderImage2: file(relativePath: { eq: "content/Shtanko_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Посещение Украины и Полтавщина| Калашников100"
        description="М.Т. Калашников всегда с трепетом относился к Украине. Посещение Полтавщины стало предвестником новой неизведанной страницы в биографии конструктора"
      />
      <Content back="/map/">
        <h1>Украина</h1>
        <div className={`${utils.maxWidth} ${styles.contentWidth}`}>
          <ResponsiveVideo
            href="https://kalashnikov.media/embed/ck2p4mvb709390947jn2rnad7"
            title="Николай Швец, полковник запаса, доктор экономических наук (в
              1998-2007&nbsp;гг работал в&nbsp;системе военно-технического
              сотрудничества&nbsp;РФ с&nbsp;иностранными государствами)"
            margin
          />

          <p>
            &mdash;&nbsp;В&nbsp;целом, Михаил Тимофеевич очень трепетно
            относился к&nbsp;Украине, к этому краю. Что интересно, почти при
            каждой встрече, когда речь заходила об&nbsp;Украине,
            он&nbsp;вспоминал строки Александра Сергеевича Пушкина из&nbsp;его
            поэмы &laquo;Гусар&raquo;:
          </p>

          <p>
            То&nbsp;ль дело Киев! Что за&nbsp;край!
            <br />
            Валятся сами в&nbsp;рот галушки,
            <br />
            Вином&nbsp;&mdash; хоть пару поддавай,
            <br />
            А&nbsp;молодицы-молодушки!
          </p>

          <p>
            То&nbsp;есть это всегда такой веселый, интересный оттенок
            в&nbsp;отношении к Украине Михаила Тимофеевича.
          </p>

          <br />
          <h2>
            <b>Первые шаги Михаила Тимофеевича на&nbsp;полтавской земле</b>
          </h2>

          <p>
            Шел 2005&nbsp;год, от&nbsp;губернатора Полтавской области было
            получено приглашение Михаилу Тимофеевичу посетить Полтавскую
            область, тем более, что в&nbsp;2009 году наступала дата 300-летия
            победы Петра I&nbsp;под Полтавой.
          </p>
          <p>
            Михаил Тимофеевич с&nbsp;удовольствием отнесся к&nbsp;этому
            приглашению. Ему также хотелось побывать в&nbsp;тех краях,
            в&nbsp;Диканьке, воспетой Гоголем, в музее-заповеднике Николая
            Васильевича.
          </p>
          <p>
            Такая встреча состоялась по&nbsp;решению руководства. Михаил
            Тимофеевич прибыл на&nbsp;полтавскую землю и&nbsp;был очень тронут
            тем, что хотя поезд прибывал в&nbsp;5.30&nbsp;утра, на&nbsp;вокзале
            было очень много людей, которые пришли встречать Михаила Тимофеевича
            Калашникова. Но&nbsp;самое трогательное было, когда заиграл духовой
            оркестр, который состоял из 9-10-летних мальчишек Великобудищенской
            средней школы, при которой существовал этот духовой оркестр. Потом
            было вручение хлеба и&nbsp;соли заместителем губернатора Полтавской
            области, а&nbsp;после этого был народный вокальный ансамбль
            &bdquo;Криница&ldquo;, который дальше сопровождал в это раннее утро
            первые шаги Михаила Тимофеевича на&nbsp;полтавской земле.
            В&nbsp;целом на&nbsp;полтавской земле прошло много встреч Михаила
            Тимофеевича с&nbsp;военнослужащими воинских частей,
            с&nbsp;курсантами военных учебных заведений, с&nbsp;ветеранами,
            с&nbsp;руководством администрации области.
          </p>
          <p>
            Очень интересной была встреча в&nbsp;музее истории Полтавской битвы.
            Михаил Тимофеевич задержался у&nbsp;стенда, где было выставлено
            оружие того времени, и&nbsp;потом задумчиво произнес:
            &quot;Да,&nbsp;&mdash; говорит,&nbsp;&mdash; опоздал я&nbsp;почти
            на&nbsp;300&nbsp;лет. А&nbsp;так&nbsp;бы я&nbsp;помог Петру
            I&nbsp;побыстрее разгромить шведов в&nbsp;это время&quot;.
          </p>

          <br />
          <h2>
            <b>Полтавщина&nbsp;&mdash; родина предков Михаила Тимофеевича</b>
          </h2>

          <p>
            Трогательной была встреча Михаила Тимофеевича Калашникова в
            единственной школе, которую он&nbsp;посетил
            в&nbsp;Украине&nbsp;&mdash; это школа, которая находится
            на&nbsp;хуторе близ Диканьки&nbsp;&mdash; Великие Будища.
            В&nbsp;этой школе он&nbsp;встретился с&nbsp;учителями, ветеранами
            села. Примечательно, что директором этой школы был Калашник
            Александр Иванович. Даже состоялась такая интересная беседа
            по&nbsp;роду Калашников&nbsp;&mdash; как он возник, что означает эта
            фамилия. Михаил Тимофеевич в&nbsp;том числе сказал, что
            не&nbsp;исключено, что может быть и&nbsp;его предки когда-то были
            под фамилией Калашник, а&nbsp;окончание появилось, когда они стали
            жить в России. Но&nbsp;в&nbsp;это время Михаил Тимофеевич еще
            не&nbsp;знал, что буквально в&nbsp;100 километрах от&nbsp;этой
            деревни находится город, в&nbsp;котором усилиями исследователей
            биографии Михаила Тимофеевича Калашникова, во&nbsp;многом благодаря
            Александру Евгеньевичу Ужанову, была найдена метрическая запись
            в&nbsp;церковной книге о&nbsp;том, что в&nbsp;городе Славгороде
            в&nbsp;храме был крещен дед Михаила Тимофеевича Калашникова. Поэтому
            Полтавщина была предвестником новой страницы в&nbsp;истории Михаила
            Тимофеевича.
          </p>

          <br />
          <h2>
            <b>
              Дом, в&nbsp;котором конструктор оставил частичку своего сердца
            </b>
          </h2>

          <p>
            Во&nbsp;время пребывания на&nbsp;Полтавщине были две очень
            трогательные встречи. Михаил Тимофеевич был приглашен в&nbsp;дом
            ветерана войны, участника боевых действий, который был всего лишь
            на&nbsp;несколько лет моложе его,&nbsp;&mdash; Гриценко Дмитрия
            Дмитриевича. Это дом, который находится в&nbsp;Диканьке. Что
            бросилось в&nbsp;глаза, когда Михаил Тимофеевич зашел
            во&nbsp;двор&nbsp;&mdash; он&nbsp;посмотрел на&nbsp;все постройки,
            на&nbsp;все ,что имеется во&nbsp;дворе. Потом во&nbsp;время угощений
            сказал: &laquo;Надо&nbsp;же, я&nbsp;вижу, что в этом доме живет
            настоящий хозяин. У&nbsp;него здесь все сделано очень правильно,
            красиво, хорошо, ухоженно&raquo;. В&nbsp;последующем, благодаря
            хозяев за&nbsp;радушный прием, Михаил Тимофеевич сказал: &laquo;Вот,
            я&nbsp;побывал на&nbsp;Полтавщине, побывал в&nbsp;Диканьке, сегодня
            в&nbsp;вашем доме, но&nbsp;уезжая, я&nbsp;вам могу с&nbsp;чистым
            сердцем сказать, что в&nbsp;этом я&nbsp;оставляю частичку своей
            души, своего сердца&raquo;.
          </p>
          <br />
          <Img
            fluid={kalash}
            alt="Дом, в котором конструктор оставил частичку своего сердца"
          />
          <br />
          <p>
            Что интересно, сейчас этот дом в&nbsp;Диканьке так
            и&nbsp;называют&nbsp;&mdash; дом, в котором находится частичка
            сердца Михаила Тимофеевича Калашникова. А в&nbsp;школе, которой
            он&nbsp;был, находится мемориальная доска, и&nbsp;даже невзирая
            на&nbsp;перемены современности в&nbsp;отношениях с&nbsp;Украиной,
            всегда у этой мемориальной доски в&nbsp;день рождения Михаила
            Тимофеевича, в&nbsp;день его памяти, 9&nbsp;мая всегда появляются
            живые цветы, как знак благодарности конструктору за&nbsp;его
            пребывание и&nbsp;внимание и&nbsp;к&nbsp;школе, и к&nbsp;Диканьке,
            и&nbsp;к&nbsp;Полтавщине.
          </p>

          <br />
          <h2>
            <b>Заботливое отношение к&nbsp;окружающим</b>
          </h2>

          <p>
            Когда Михаил Тимофеевич ехал в&nbsp;заповедник Николая Васильевича
            Гоголя, нам пришла информация о&nbsp;том, что ветеран&nbsp;&mdash;
            участник Великой Отечественной войны, кавалер орденов Славы Штанько
            Николай Иванович из&nbsp;деревни, которая находится по&nbsp;пути
            в&nbsp;Гоголево, вышел и&nbsp;сидит на лавочке для того, чтобы
            хотя&nbsp;бы посмотреть на&nbsp;машину, в&nbsp;которой будет
            проезжать Михаил Тимофеевич Калашников. Когда мы&nbsp;ему
            по&nbsp;дороге сказали, что ветеран будет в&nbsp;такой-то деревне
            сидеть на&nbsp;лавочке, естественно, Михаил Тимофеевич остановил
            машину, пообщался, сфотографировался, они обменялись мнениями про
            житье-бытье, вспомнили войну, боевых товарищей. Николай Иванович
            Штанько тоже был ранен в&nbsp;самом начале войны и&nbsp;потом
            находился долгое время на излечении в&nbsp;госпитале.
          </p>
          <br />
          <Img fluid={kalash2} alt="Заботливое отношение к окружающим" />
          <br />
          <p>
            Этим ведь тоже проявляется вот это исключительно добродушное,
            заботливое отношение Михаила Тимофеевича к&nbsp;окружающим людям,
            которое он&nbsp;проявлял на&nbsp;протяжении всей своей жизни.
          </p>
        </div>
      </Content>
    </>
  )
}

export default MapPage
